import React,{useState,useEffect} from 'react'

import Popper from '../Popper';
import LoadingBar from 'react-top-loading-bar'
import '../pages/Security.css'
import MetaTags from 'react-meta-tags';
import ApiConfig from '../../api/ApiConfig';
import { simpleGetCallWithErrorResponse } from '../../api/ApiServices';

function Privacy() {
    const [progress, setProgress] = useState(0)
    const [content,setcontent]=useState("")

    function createMarkup(data) {
        return { __html: data };
    }
    useEffect(()=>{


        simpleGetCallWithErrorResponse(ApiConfig.PRIVACY).then((data) => {
            setcontent(data.json)

        })
            .catch((error) => {
                console.log("api response", error);
            });
    },[])


    useEffect(

        ()=>{
            setProgress(100)
            document.title="Privacy Policy - DigiWill-Keep The Track Record of Your Assets and Liabilities"
        },[]
    )
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

  return (
    <div>
        <MetaTags>
                	<title>Privacy Policy | What information do we collect?</title>
               	 <meta name="description" content="We receive and store only that information you knowingly provide to us or with your explicit consent. You can always delete this information by writing to us at support@yourDigiWill.com" />
            	</MetaTags>



{/* <Popper></Popper> */}
   

    <LoadingBar
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />  
  <section className="cxle-banner">
            <div className="container">
                <div clas="row " style={{height:"100%"}}>
                    <div className="col-12 d-flex align-items-center justify-content-center" style={{height:"100%"}}>
                        <div className="cxle-banner-content">
                            <span>Privacy Policy</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="policy">
        <div className="policy_block">
            <div className="container">
            {
    content ? 
    <div>
   <p dangerouslySetInnerHTML={createMarkup(content)} /> 
    </div>

    :""
}
            </div>
        </div>
    </section>




    </div>
  )
}

export default Privacy