import React, { useState, useEffect,useContext } from 'react';

import Popper from '../Popper';
import { BASE_URL, PLAYSTORE_URL } from './urls';
import { useAlert } from 'react-alert'
import { positions } from 'react-alert'
import LoadingBar from 'react-top-loading-bar'
import FooterEnsurance from './FooterEnsurance';
import ReactGA from 'react-ga';
import { TRACKING_CODE } from './urls';
import MetaTags from 'react-meta-tags';
import { AppContext } from '../../user/AppContext';
ReactGA.initialize(TRACKING_CODE);
function Ensurance() {
    const [registerData, setRegisterData] = useState({ name: '', email: "", phone_number: "" })
    const [progress, setProgress] = useState(0)
    const alert = useAlert()
    const width = window.innerWidth
    const {show,setShow}=useContext(AppContext)
    useEffect(() => {

        if (width <= 972) {
          setShow('none')
        }
        else{
          setShow('flex')
        }
    
      }, [])
      useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(

        () => {
            setProgress(100)
            document.title = "Ensurance - DigiWill-Keep The Track Record of Your Assets and Liabilities"
        }, []
    )
    useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'visited Ensurance page'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    /* handle register start */
    const handleRegister = async (e) => {
        e.preventDefault()
        alert.show('Hi thank you for choosing us ! Our Representative will contact you shortly..', { position: positions.TOP_CENTER })

        const { name, email, phone_number } = e.target
        const data = new FormData();

        data.append('name', name.value)
        data.append('email', email.value)
        data.append("phone_number", phone_number.value)

        console.log(data);
        const response = await fetch(`${BASE_URL}temp_ensurance`, {
            method: "POST",
            body: data,
        })
        const newdata = await response.json()
        console.log(newdata);

        setRegisterData({ name: '', email: "", phone_number: "" })
        setTimeout(() => {
            setProgress(25)
        }, 100);
        setTimeout(() => {
            setProgress(70)
        }, 100);
        setTimeout(() => {
            setProgress(100)
        }, 100);
        ReactGA.event({
            category: 'User',
            action: `${name} Submitted Ensurance form`
        });
        /*     window.location=PLAYSTORE_URL */


    }

    /* handle register end  */

    /* form onchange event start */

    const handleChange = (e) => {

        if (e.target.name === 'name') {

            setRegisterData({ ...registerData, name: e.target.value })
        }


        if (e.target.name === 'email') {

            setRegisterData({ ...registerData, email: e.target.value })
        }


        if (e.target.name === 'phone_number') {

            setRegisterData({ ...registerData, phone_number: e.target.value })
        }

    }

    /* form onchange event end  */

    return (<div>
        <MetaTags>
            <title>Ensurance Plan | Doorstep Claim Assistance - DigiWill</title>
            <meta name="description" content="We provide hassle-free doorstep claim assistance service for any type of assets, insurances, etc. for you and your loved ones." />
        </MetaTags>

        {/* <Popper></Popper> */}
       

        <LoadingBar
            color='#f11946'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
        />
        <section id="cxpr-banner">
            <div className="container">
                <div className="cxpr-banner-content">
                    <div className="cxpr-quotes">
                        <h2>Don't let your Loved One's Struggle, To get What is Rightfully Theirs</h2>
                        <p>Ensurance Plan at Just ₹10/day </p>
                    </div>
                    <div className="cxpr-sub-form">
                        <form onSubmit={(e) => handleRegister(e)}>
                            <h4>Ensurance Plan</h4>
                            <p>Fill in the below form to get started with ensurance plan</p>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="cx-form-group">
                                        <label for="cxpr-fname">Full Name</label>
                                        <input type="text" required name='name' value={registerData.name} onChange={(e) => handleChange(e)} className="form-control" id="cxpr-fname" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="cx-form-group">
                                        <label for="cxpr-pnumber">Phone Number</label>
                                        <input type="text" required name='phone_number' value={registerData.phone_number} onChange={(e) => handleChange(e)} className="form-control" id="cxpr-pnumber" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="cx-form-group">
                                        <label for="cxpr-email">Email</label>
                                        <input type="email" required name='email' value={registerData.email} onChange={(e) => handleChange(e)} className="form-control" id="cxpr-email" placeholder="" />
                                    </div>
                                </div>
                                {/*  <div className="col-sm-6">
                                    <label for="" style="margin-bottom:10px;">Address 1</label>
                                    <div className="cx-form-group cx-form-nom">
                                        <textarea className="form-control"></textarea>
                                    </div>
                                </div> */}
                                {/*  <div className="col-sm-6">
                                    <label for="" style="margin-bottom:10px;">Address 2(Optional)</label>
                                    <div className="cx-form-group cx-form-nom">
                                        <textarea className="form-control"></textarea>
                                    </div>
                                </div> */}
                                {/* <div className="col-sm-12">
                                    <label for="" style="margin-bottom:10px;">Nominee</label>
                                    <div className="cx-form-group cx-form-nom">
                                        <input type="text" className="form-control" id="cxpr-email" placeholder="Full Name" />
                                        <input type="text" className="form-control" id="cxpr-email" placeholder="Email" />
                                        <input type="text" className="form-control" id="cxpr-email" placeholder="Phone Number" />
                                        <i className='bx bxs-plus-circle'></i>
                                    </div>
                                    <div className="cx-form-group cx-form-nom">
                                        <input type="text" className="form-control" id="cxpr-email" placeholder="Full Name" />
                                        <input type="text" className="form-control" id="cxpr-email" placeholder="Email" />
                                        <input type="text" className="form-control" id="cxpr-email" placeholder="Phone Number" />
                                        <i className='bx bxs-minus-circle' ></i>
                                    </div>
                                </div> */}
                            </div>
                            <div className="cx-btn-grp">
                                <button type="submit" className="cxpr-proceed">Get Started</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>


        <section id="cxpr-benefits">
            <div className="container">
                <div className="cxpr-benefits-content">
                    <h2>All of the below at your dooorstep.</h2>
                    <p className="cxpr-plan">Just @₹3650/year</p>
                    <p>DigiWill gives you hassle-free doorstep assistance for you and your loved ones. In the most unforeseen events of your life, we ensure that all your assets are claimed by your family. We offer free of cost minimal affidavits and NOC’s, drafted by our expert lawyers, for claim-related work. Apart from that, our investment record-keeping software provides you with:</p>
                    <div className="row">

                        <div className="col-md-6">
                            <div className="cxpr-content">
                                <img src="images/promotion/family.png" alt='female-service-managers' />
                                <p>Female service managers to help women and children.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="cxpr-content">
                                <img src="images/promotion/arrived.png" alt='Personalized-claim' />
                                <p>Personalized end to end handholding claim assistance at your doorstep.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="cxpr-content">
                                <img src="images/promotion/agreement.png" alt='Free-of-cost-document' />
                                <p>Free of cost, creation of minimal legal documents like affidavits, NOCs, etc.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="cxpr-content">
                                <img src="images/promotion/access.png" alt='DigiWill-app' />
                                <p>Free life time access to DigiWill app.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="cxpr-content">
                                <img src="images/promotion/cab.png" alt='cab-claim' />
                                <p>Free cab for claim related works.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="cxpr-content">
                                <img src="images/promotion/cloud_data.png" alt='additional-cloud' />
                                <p>Additional cloud storage space for documents.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="cxpr-content">
                                <img src="images/promotion/lawyer.png" alt='free-consultaion' />
                                <p>Free consultation from our lawyers and claim experts for all your asset claiming process.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="cxpr-content">
                                <img src="images/promotion/service.png" alt='special-service-manager' />
                                <p>Special service manager with one and one access at your doorstep.</p>
                            </div>
                        </div>
                    </div>
                    <p className="cxpr-note">Note: We are in process of adding 2 lacs worth Life Insurance Cover to the DigiWill Ensurance Plan through <a href="https://financialservices.gov.in/insurance-divisions/Government-Sponsored-Socially-Oriented-Insurance-Schemes/Pradhan-Mantri-Jeevan-Jyoti-Bima-Yojana(PMJJBY)" >PMJJBY</a>. Even if you buy the plan today, you will be eligible for the 2 lacs claim when we are done adding the cover to DigiWill Ensurance Plan.</p>
                </div>
            </div>
        </section>


        <FooterEnsurance />



    </div>);
}

export default Ensurance;
