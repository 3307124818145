export
    const BASE_URL = "https://prod.yourdigiwill.com/web/";
    const BASE_URLS= "https://prod.yourdigiwill.com/api/";
    const TEST_BASE_URLS ="https://dev.yourdigiwill.com/api/"

const ApiConfig = {
    SERVICE_PARTNER: BASE_URL + "service_partner_website",
    CHANNEL_PARTNER: BASE_URL + "channel_partner_website",
    GET_IN_TOUCH: BASE_URL + "get_in_touch",
    REQUEST_CALLBACK: BASE_URL + "request_call_back",
    // ENSURANCE_FORM_DATA:BASE_URL+ "temp_ensurance",
    CLAIM_IT_PAGE: BASE_URL + "claim_it",
    DigiWill_TEAM_ABOUT_PAGE: BASE_URL + "digiwill_teams",
    BLOG_DISPLAY: BASE_URL + "blog",
    BLOG_CATEGORY: BASE_URL + "get-category",
    BLOG_CATEGORY_SEARCH: BASE_URL + "categories-blog",
    BLOG_AFTER_OPEN_DATA: BASE_URL + "blog",
    LATEST_FIVE_BLOG: BASE_URL + "latest_blog",
    BLOG_SAVE_COMMENT: BASE_URL + "savecommentapi",
    BLOG_FAQ: BASE_URL + "get_faq",
    TESTIMONIALS:BASE_URL+ "testimonialsapi",
    B2BPAGE:BASE_URLS + "add_b2b",
    CLAIMASSISTANCELANDINGAPGE:BASE_URL+"claim-assistance",
    APPLANDINGPHOMEEMAILFORM:BASE_URL+"pre_registraion",
    ENQUIRY_FORM:BASE_URLS+"enquiry_form",

      // B2B API 
      LOGIN_PHONE_NO:TEST_BASE_URLS+"b2b_login",
      OTP_VERIFY_SCREEN:TEST_BASE_URLS+"b2b_verify_otp",
      UPLOAD_DOCUMENT:TEST_BASE_URLS+"upload_doc_b2b",
    TERMS:BASE_URL+"terms",
    PRIVACY:BASE_URL+"security",
    DESCLAIMER:BASE_URL+"desclaimer"
}
export default ApiConfig;