import React, { useEffect, useState, useRef, useContext } from 'react';


import ReactGA, { event } from 'react-ga';
import { TRACKING_CODE } from './urls';
import LoadingBar from 'react-top-loading-bar';
import MetaTags from 'react-meta-tags';
import {
    OverlayTrigger, Tooltip
} from 'react-bootstrap';

import { AppContext } from '../../user/AppContext';
ReactGA.initialize(TRACKING_CODE);

function Pricing() {
    const [progress, setProgress] = useState(0)
    const [show1, setShow1] = useState(false);
    const [value, setValue] = useState(9999)
    const [name, setName] = useState("")
    const [name1, setName1] = useState("Validty Lifetime")
    const [name2, setName2] = useState("Pay for 10 year")
    const [name3, setName3] = useState("20000/-")
    const [name5, setName5] = useState("Limited offers")
    const [name6, setName6] = useState("Platinum")
    const [showMessage, setShowMessage] = useState(true);
    const [proPlan,setProPlan]=useState("")
    const width = window.innerWidth
    const { show, setShow } = useContext(AppContext)
    useEffect(() => {

        if (width <= 972) {
            setShow('none')
        }
        else {
            setShow('flex')
        }

    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        setTimeout(function () {
            setShowMessage(false);
        }, 10000);
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            {/* <Popper></Popper> */}
            <MetaTags>
                <title> Our Pricing | Yearly & Lifetime Subscription Plan</title>
                <meta name="description" content="Get to know DigiWill’s yearly and lifetime subscription plans. We have different features available." />
            </MetaTags>
            <LoadingBar
                color='#f11946'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <main>
                {/* this is desktop view start */}
                <section id="" className='cxp-wrapper-table'>
                    <div className="cxp-outer">

                        <div className="cx-content-header">
                            <h2 className='mb-5'>Pricing</h2>
                        </div>
                        <div class="container">
                            <div class="pricing-switcher">
                                <p class="fieldset">
                                    <input type="radio" checked={value==1825} name="duration-1" value="monthly" id="monthly-1" onClick={() => { setValue(1825); setName(""); setName1("Validity One Year"); setName3('3650/-'); setName5('Limited offers'); setName6("Pro");setProPlan('abc') }} />
                                    <label for="monthly-1">Yearly</label>
                                    <input type="radio" checked={value!=1825}  name="duration-1" value="yearly" id="yearly-1" onClick={() => { setValue(9999); setName(""); setName1("Validity LifeTime"); setName2(''); setName3("20000/-"); setName5("Limited offers"); setName6('Platinum');setProPlan("") }} />
                                    <label for="yearly-1">LifeTime</label>
                                    <span class="switch"></span>
                                </p>
                            </div>
                        </div>
                        <div class="container-fluid" id='cxp-entails-tables'>
                            <div class="row">
                                <div className="col-md-12 mt-1 mb-3 col-sm-12 ">
                                    <div>
                                        <table className='w-100'>
                                            <th className='w-25'></th>
                                            <th className='w-25'></th>
                                            <th className="w-25 text-end third-cell "><span class="blink_me">{name1 == "Validity LifeTime" ? "Recommended" : ""}</span></th>
                                            <th className='w-25'></th>
                                        </table>
                                    </div>
                                    <div className='subscription-wrapper-main'>
                                        <div className="subscription-wrapper d-flex ">
                                            <div className='subscription-col subscription-col-2'>
                                                <div className="subscription-col-header">

                                                    <div className='text-center'><h3>Our Services</h3></div>
                                                    <div className='sub-col-header-bottom '>

                                                        <p className='sub-bottom-para1 fs-5 '>All Features Offered By Us.</p>
                                                    </div>

                                                </div>

                                                <div className="subscription-col-body">
                                                    <ul id='menu'>

                                                        <li >
                                                            <p>Asset Discovery<span>{['top'].map((placement) => (
                                                                <OverlayTrigger
                                                                    key={placement}
                                                                    placement={placement}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${placement}`}>
                                                                            Ai powered discovery of all financial & non-financial assets
                                                                        </Tooltip>
                                                                    }
                                                                >

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle ms-1" viewBox="0 0 16 16" >
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            ))}</span> </p>
                                                        </li>
                                                        <li >
                                                            <p>Asset Addition<span>{['top'].map((placement) => (
                                                                <OverlayTrigger
                                                                    key={placement}
                                                                    placement={placement}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${placement}`}>
                                                                            Manual asset addition
                                                                        </Tooltip>
                                                                    }
                                                                >

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle ms-1" viewBox="0 0 16 16" >
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            ))}</span> </p>
                                                        </li>
                                                        <li >
                                                            <p>Asset Updates <span>{['top'].map((placement) => (
                                                                <OverlayTrigger
                                                                    key={placement}
                                                                    placement={placement}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${placement}`}>
                                                                            Ai powered automatic updates of all financial and non-financial asset records
                                                                        </Tooltip>
                                                                    }
                                                                >

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle ms-1" viewBox="0 0 16 16" >
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            ))}</span> </p>
                                                        </li>
                                                        <li>
                                                            <p>Central Nomination<span>{['top'].map((placement) => (
                                                                <OverlayTrigger
                                                                    key={placement}
                                                                    placement={placement}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${placement}`}>
                                                                            Nominee management across all investments, savings and assets from one single platform.
                                                                        </Tooltip>
                                                                    }
                                                                >

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle ms-1" viewBox="0 0 16 16" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            ))}</span></p>
                                                        </li>
                                                        <li>
                                                            <p>Nominee Addition / Updates <span>{['top'].map((placement) => (
                                                                <OverlayTrigger
                                                                    key={placement}
                                                                    placement={placement}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${placement}`}>
                                                                            Nominee & Nominee contact details updates through your phone
                                                                        </Tooltip>
                                                                    }
                                                                >

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle ms-1" viewBox="0 0 16 16" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            ))}</span></p>
                                                        </li>
                                                        <li>
                                                            <p>Succession Managers<span>{['top'].map((placement) => (
                                                                <OverlayTrigger
                                                                    key={placement}
                                                                    placement={placement}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${placement}`}>
                                                                            Sharing & communication of your assets with your CA, Lawyers, Wealth-Managers, etc.
                                                                        </Tooltip>
                                                                    }
                                                                >

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle ms-1" viewBox="0 0 16 16" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            ))}</span></p>
                                                        </li>
                                                        <li>
                                                            <p>Express Will <span>{['top'].map((placement) => (
                                                                <OverlayTrigger
                                                                    key={placement}
                                                                    placement={placement}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${placement}`}>
                                                                            click E-signed will creation & Auto-will creation
                                                                        </Tooltip>
                                                                    }
                                                                >

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle ms-1" viewBox="0 0 16 16" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            ))}</span></p>
                                                        </li>
                                                        <li>
                                                            <p>Record Communication<span>{['top'].map((placement) => (
                                                                <OverlayTrigger
                                                                    key={placement}
                                                                    placement={placement}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${placement}`}>
                                                                            Events of communication of wills and financial records
                                                                        </Tooltip>
                                                                    }
                                                                >

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle ms-1" viewBox="0 0 16 16" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            ))}</span></p>
                                                        </li>
                                                        <li>
                                                            <p>Linked Account <span>{['top'].map((placement) => (
                                                                <OverlayTrigger
                                                                    key={placement}
                                                                    placement={placement}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${placement}`}>
                                                                            Real time communication of assets

                                                                        </Tooltip>
                                                                    }
                                                                >

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle ms-1" viewBox="0 0 16 16" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            ))}</span></p>
                                                        </li>
                                                        <li>
                                                            <p>Human Interference Free <span>{['top'].map((placement) => (
                                                                <OverlayTrigger
                                                                    key={placement}
                                                                    placement={placement}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${placement}`}>
                                                                            No human dependency required for Will & financial records communication with your nominees

                                                                        </Tooltip>
                                                                    }
                                                                >

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle ms-1" viewBox="0 0 16 16" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            ))}</span></p>
                                                        </li>
                                                        <li>
                                                            <p>Life status tracker Ai<span>{['top'].map((placement) => (
                                                                <OverlayTrigger
                                                                    key={placement}
                                                                    placement={placement}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${placement}`}>
                                                                            Track a person's Life Status with Ai

                                                                        </Tooltip>
                                                                    }
                                                                >

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle ms-1" viewBox="0 0 16 16" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            ))}</span></p>
                                                        </li>
                                                        <li>
                                                            <p>After you Automated Wealth Transmission <span>{['top'].map((placement) => (
                                                                <OverlayTrigger
                                                                    key={placement}
                                                                    placement={placement}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${placement}`}>
                                                                            Updates Asset Records & Transmits Human Interference Free Financial Records & Wills transfer to registered nominees
                                                                        </Tooltip>
                                                                    }
                                                                >

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle ms-1" viewBox="0 0 16 16" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            ))}</span></p>
                                                        </li>
                                                        <li>
                                                            <p>Tokenized + Encrypted Digital Records <span>{['top'].map((placement) => (
                                                                <OverlayTrigger
                                                                    key={placement}
                                                                    placement={placement}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${placement}`}>
                                                                            State of Art tokenization + encryption for financial records
                                                                        </Tooltip>
                                                                    }
                                                                >

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle ms-1" viewBox="0 0 16 16" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            ))}</span></p>
                                                        </li>


                                                        <li>
                                                            <p>Asset Distribution <span>{['top'].map((placement) => (
                                                                <OverlayTrigger
                                                                    key={placement}
                                                                    placement={placement}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${placement}`}>
                                                                            In-app single platform to distribute and redistribute assets among your loved ones
                                                                        </Tooltip>
                                                                    }
                                                                >

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle ms-1" viewBox="0 0 16 16" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            ))}</span></p>
                                                        </li>
                                                        <li>
                                                            <p>Dedicated Service Manager <span>{['top'].map((placement) => (
                                                                <OverlayTrigger
                                                                    key={placement}
                                                                    placement={placement}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${placement}`}>
                                                                            Single point service
                                                                        </Tooltip>
                                                                    }
                                                                >

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle ms-1" viewBox="0 0 16 16" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            ))}</span></p>
                                                        </li>
                                                        <li>
                                                            <p>Data Storage <span>{['top'].map((placement) => (
                                                                <OverlayTrigger
                                                                    key={placement}
                                                                    placement={placement}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${placement}`}>
                                                                            Free unlimited data storage for financial records & wills
                                                                        </Tooltip>
                                                                    }
                                                                >

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle ms-1" viewBox="0 0 16 16" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            ))}</span></p>
                                                        </li>
                                                        <li>
                                                            <p>Financial & Legal Advisory <span>{['top'].map((placement) => (
                                                                <OverlayTrigger
                                                                    key={placement}
                                                                    placement={placement}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${placement}`}>
                                                                            Free of cost minimal document creation
                                                                        </Tooltip>
                                                                    }
                                                                >

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle ms-1" viewBox="0 0 16 16" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            ))}</span></p>
                                                        </li>

                                                        <li>
                                                            <p>Claim It <span>{['top'].map((placement) => (
                                                                <OverlayTrigger
                                                                    key={placement}
                                                                    placement={placement}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${placement}`}>
                                                                            All asset hand-held door-step claim service for dependants & Nominees
                                                                        </Tooltip>
                                                                    }
                                                                >

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle ms-1" viewBox="0 0 16 16" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            ))}</span></p>
                                                        </li>
                                                        <li>
                                                            <p>Platform Access <span>{['top'].map((placement) => (
                                                                <OverlayTrigger
                                                                    key={placement}
                                                                    placement={placement}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${placement}`}>

                                                                        </Tooltip>
                                                                    }
                                                                >

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle ms-1" viewBox="0 0 16 16" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            ))}</span></p>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='subscription-col subscription-col-1'>
                                                <div className="subscription-col-header">

                                                    <div className='text-center'><h3>Free </h3></div>
                                                    <div className='sub-col-header-bottom '>
                                                        <span className='mb-2 fw-bold'>&#x20B9; 0 /-</span>
                                                        <p className='sub-bottom-para1'>All the basic feature are included in this.</p>

                                                        <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp"> <button class="btn btn-outline-success mb-2 text-dark">Subscribe</button></a>
                                                    </div>

                                                </div>
                                                <div className="subscription-col-body">
                                                    <ul className='first-sub-list'>
                                                        <li >-</li>
                                                        <li >5</li>
                                                        <li >-</li>
                                                        <li >-</li>

                                                        <li className='list' >3</li>
                                                        <li >1</li>
                                                        <li >-</li>
                                                        <li >-</li>
                                                        <li >-</li>
                                                        <li >-</li>
                                                        <li >-</li>
                                                        <li>-</li>
                                                        <li>-</li>
                                                        <li>-</li>
                                                        <li>-</li>
                                                        <li>-</li>
                                                        <li>-</li>
                                                        <li>-</li>
                                                        <li>For limited time period only</li>


                                                    </ul>
                                                </div>
                                            </div>

                                            <div className='subscription-col subscription-col-1 ' id='sub-2-hover'>
                                                <div className="subscription-col-header">
                                                    <div className='text-center '> <h3 className='text-dark'>{name6}</h3>
                                                    </div>
                                                    <div className='sub-col-header-bottom'>
                                                        {name5 ?
                                                            <div className='new-robbon-wrapper'>
                                                                <p className='new-ribbon'>{name5}</p>
                                                            </div> : ''
                                                        }

                                                        <span className='mb-1' style={{ textDecoration: "line-through" }}>{name3 == 20000 ? "₹" : ""}{name3}</span>
                                                        <span className='mb-2  fw-bold'>&#x20B9; {value} /- </span>

                                                        <p className='sub-bottom-para1'>{name1}</p>
                                                        <p className='sub-bottom-para2'>{name}</p>
                                                        <p className='sub-bottom-para2'></p>


                                                        <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp"> <button class="btn btn-outline-success mb-2 text-dark">Subscribe</button></a>
                                                    </div>

                                                </div>
                                                <div className="subscription-col-body">
                                                    <ul className='sub-list'>
                                                        <li className=''><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>
                                                        <li className=''><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>
                                                        <li className=''><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>
                                                        <li className=''><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>
                                                     
                                                         <li className=''>{proPlan?"10":<svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg>}</li>
                                                        <li className=''>{proPlan?"5":<svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg>}</li>
                                                        <li className=''>{proPlan?"2":<svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg>}</li>
                                                        <li className=''><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>
                                                        <li className=''><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>

                                                        <li className=''><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>

                                                        <li className=''><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>
                                                        <li className=''><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>
                                                        <li className=''><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>
                                                        <li className=''><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li><li className=''><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li><li className=''><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li><li className=''><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li><li className=''><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>
                                                        <li>{proPlan?"Valid For 1 Year":"Valid For Lifetime"}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='subscription-col subscription-col-1'>
                                                <div className="subscription-col-header">

                                                    <div className='text-center'><h3>After You</h3></div>
                                                    <div className='sub-col-header-bottom'>
                                                        <div className='new-robbon-wrapper'>
                                                            <p className='new-ribbon'>Limited Offers</p>
                                                        </div>
                                                        <span className='span-line mb-1'>&#x20B9; 9999 /-</span>
                                                        <span className='mb-2 fw-bold'>&#x20B9; 4999 /-</span>
                                                        <p className='sub-bottom-para1'>Validty LifeTime</p>


                                                        <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp"> <button class="btn btn-outline-success mb-2 text-dark">Subscribe</button></a>
                                                    </div>

                                                </div>
                                                <div className="subscription-col-body">
                                                    <ul className='sub-list'>
                                                        <li className='list'><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>
                                                        <li ><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>
                                                        <li className='list'><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>
                                                        <li ><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>
                                                        <li className='list'>10</li>
                                                        <li className=''>5</li>
                                                        <li className=''>1</li>
                                                        <li className=''><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>
                                                        <li className=''><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>

                                                        <li className=''><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>

                                                        <li className=''><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>
                                                        <li className=''><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>
                                                        <li className=''><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>
                                                        <li><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>
                                                        <li>-</li>
                                                        <li><svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="25.315" viewBox="0 0 27.562 25.315">
                                                            <g id="check-mark" transform="translate(-31.92 461.918)">
                                                                <path id="Path_344" data-name="Path 344" d="M55.687-461.861a36.788,36.788,0,0,0-4.017,2.953,73.1,73.1,0,0,0-10.045,10.34,12.482,12.482,0,0,1-.861,1.033c-.025.006-1.384-.929-3.014-2.067s-3.02-2.1-3.088-2.153a.734.734,0,0,0-.332-.08c-.2,0-.314.1-1.31,1.089-1.353,1.353-1.329,1.255-.621,2.227,3.279,4.521,6.7,9.491,7.707,11.183.246.424.388.6.541.67a.555.555,0,0,0,.646-.086c.049-.055.406-.634.787-1.273a151.336,151.336,0,0,1,10.291-15.193,62.354,62.354,0,0,1,5.518-6.256c1.489-1.482,1.593-1.6,1.593-1.808a.53.53,0,0,0-.308-.547,5.9,5.9,0,0,0-1.765-.086A15.436,15.436,0,0,0,55.687-461.861Z" transform="translate(0 0)" fill="#20c997" />
                                                            </g>
                                                        </svg></li>
                                                        <li>-</li>
                                                        <li>-</li>
                                                        <li>Valid for Lifetime</li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                {/* this is desktop view end */}

                {/* this is mobile view start */}
                <section id='mobile-view'>
                    <div className="container">
                        <div class="row card-mobile">
                            <div class="col-md-4">
                                <div class="card  mb-4">
                                    <div class="card-body text-primary">
                                        <h5 class="card-title">Free</h5>
                                        <h3 className=''>&#x20B9; 0 /-</h3>
                                        <p class="card-text">All the basic feature are included in this.</p>
                                        <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp"> <button class="btn btn-outline-success mb-2 text-dark">Subscribe</button></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card  mb-4">
                                    <div class="card-body text-primary">
                                        <h5 class="card-title">{name6}</h5>
                                        <div className='card-text'>
                                            {name5 ?
                                                <div className='new-robbon-wrapper-mob'>
                                                    <p className='new-ribbon-mob'>{name5}</p>
                                                </div> : ''
                                            }</div>
                                        <h4 className='mb-3 mt-5' style={{ textDecoration: "line-through" }}>{name3 == 20000 ? " ₹ " : ""}{name3}</h4>
                                        <h3 className=''>&#x20B9; {value} /-</h3>
                                        <p class="card-text">{name}</p>
                                        <p class="card-text">{name1}</p>
                                        <p className='card-text'>{name2}</p>


                                        <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp"> <button class="btn btn-outline-success mb-2 text-dark">Subscribe</button></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card  mb-4">
                                    <div class="card-body text-primary">
                                        <h5 class="card-title">After you</h5>
                                        <div className='card-text'>
                                            <div className='new-robbon-wrapper-mob'>
                                                <p className='new-ribbon-mob'>Limited Offer</p>
                                            </div>
                                        </div>

                                        <h4 className='mb-3 mt-5 ' style={{ textDecoration: "line-through" }}>&#x20B9; 9999/-</h4>
                                        <h3 className=''>&#x20B9; 4999 /-</h3>
                                        <p class="card-text">Validty LifeTime.</p>
                                        <p>(Limited Time Offer)</p>
                                        <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp"> <button class="btn btn-outline-success mb-2 text-dark">Subscribe</button></a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div >
                            <table class="table">
                                <thead className='tab-head'>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col"></th>

                                    </tr>
                                </thead>
                                <tbody className='tab-body'>
                                    <tr>
                                        <th scope="row">Free</th>
                                        <td><ul>
                                            <li>Asset Addition-5</li>
                                            <li>Nominee Addition / Updates-3</li>
                                            <li>Succession Managers-1</li>
                                            <li>Platform Access-For limited time period only</li>
                                            

                                        </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Platform Access</th>
                                        <td>
                                            <ul>
                                                <li>Asset Discovery</li>
                                                <li>Asset Addition</li>
                                                <li> Asset Updates</li>
                                                <li>Central Nomination</li>
                                                <li>Nominee Addition / Updates</li>
                                                <li>Express Will</li>
                                                <li>Succession Managers</li>
                                                <li>Record Communication </li>
                                                <li>Linked Account </li>
                                                <li>Human Interference Free </li>
                                                <li> Life status tracker Ai</li>
                                                <li>After you Automated Wealth Transmission </li>
                                                <li>Tokenized + Encrypted Digital Records </li>
                                                <li>Asset Distribution </li>
                                                <li>Dedicated Service Manager </li>
                                                <li>Data Storage</li>
                                                <li>Financial & Legal Advisory  </li>
                                                <li>Claim It </li>
                                                <li>Platform Access-Valid For Lifetime  </li>
                                               
                                            </ul>


                                        </td>

                                    </tr>
                                    <tr>
                                        <th scope="row">After You</th>

                                        <td><ul>
                                        <li>Asset Discovery</li>
                                                <li>Asset Addition</li>
                                                <li> Asset Updates</li>
                                                <li>Central Nomination</li>
                                                <li>Nominee Addition / Updates-10</li>
                                                <li>Express Will-5</li>
                                                <li>Succession Managers-1</li>
                                                <li>Record Communication </li>
                                                <li>Linked Account </li>
                                                <li>Human Interference Free </li>
                                                <li> Life status tracker Ai</li>
                                                <li>After you Automated Wealth Transmission </li>
                                                <li>Tokenized + Encrypted Digital Records </li>
                                                <li>Asset Distribution </li>
                                               
                                                <li>Data Storage</li>
    
                                                <li>Platform Access-Valid For Lifetime  </li>
                                        </ul></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                </section>
                {/* this is mobile view end */}

            </main>
            <section id="cxp-download" style={{ margiBottom: "100px" }}>
                <div className="container">
                    <div className="cxp-outer">
                        <div className="cxp-download-content">
                            <div className="cxp-download-btn">
                                {/* <a href="#" style={{marginRight:"8rem"}}><img src="./images/home/google_play.png" alt='optional' /></a> */}
                                {/*      <a href="#"><img src="./images/home/google_play.png" /></a> */}
                            </div>
                            <h4>Download India's Only One-Stop Financial & Non-Financial Asset Inheritance Solution.</h4>
                            <div >
                                <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" className="cx-banner-btns"><img src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/google-play-badge.png" style={{ height: "65.79px", width: "170px" }} alt="DigiWill App - Get it on Google Play Store" /></a>
                                {/*    <a href="#"><img src="images/home/app_store.png" /></a> */}


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Pricing;












