import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Redirect } from 'react-router';
import Popper from '../Popper';
import { BASE_URLS, PLAYSTORE_URL, TRACKING_CODE } from './urls';
import { useAlert } from 'react-alert'
import { positions } from 'react-alert'
import LoadingBar from 'react-top-loading-bar'

import ReactGA from 'react-ga';
import MetaTags from 'react-meta-tags';
import { AppContext } from '../../user/AppContext';
ReactGA.initialize(TRACKING_CODE);
function AppSoon() {
    let navigate = useNavigate();
    const width = window.innerWidth
    const { show, setShow } = useContext(AppContext)
    useEffect(() => {

        if (width <= 972) {
            setShow('none')
        }
        else {
            setShow('flex')
        }

    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        ReactGA.event({
            category: 'claim-assistance',
            action: 'Thank you for filling out the details. Our team will get back to you shortly.e'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])
    const [progress, setProgress] = useState(0)
    const alert = useAlert()
    const [registerData, setRegisterData] = useState({ full_name: '', email: "", mobile_no: "", city: "",  })
    //const [success,setsuccess]=useState(false)

    useEffect(

        () => {
            setProgress(100)
            document.title = "claim-assistance - DigiWill-Keep The Track Record of Your Assets and Liabilities"
        }, []
    )
    const handleRegister = async (e) => {
        e.preventDefault()
       // window.location.reload();
        alert.show('Thank you for filling out the details. Our team will get back to you shortly.', { position: positions.TOP_CENTER })

        const { full_name, email, mobile_no, city } = e.target
        const data = new FormData();

        data.append('full_name', full_name.value)
        data.append('email', email.value)
        data.append("mobile_no", mobile_no.value)
        data.append('city', city.value)
       
        console.log(data);
        const response = await fetch(`${BASE_URLS}app-launching-soon`, {
            method: "POST",
            body: data,
        })
            // .then(() => {
            //    // navigate("/thank-you", { replace: true });
            // })
        const newdata = await response.json()
        console.log(newdata);
        //setsuccess(true)
        setRegisterData({ full_name:'', email:"", mobile_no:"", city:"" })
        setTimeout(() => {
            setProgress(25)
        }, 100);
        setTimeout(() => {
            setProgress(70)
        }, 100);
        setTimeout(() => {
            setProgress(100)
        }, 100);

    }

    const handleChange = (e) => {

        if (e.target.name === 'full_name') {

            setRegisterData({ ...registerData, full_name: e.target.value })
        }

        if (e.target.name === 'email') {

            setRegisterData({ ...registerData, email: e.target.value })
        }

        if (e.target.name === 'mobile_no') {

            setRegisterData({ ...registerData, mobile_no: e.target.value })
        }

        if (e.target.name === 'city') {

            setRegisterData({ ...registerData, city: e.target.value })
        }

    }

    // faq section js write here start
   // const ref = useRef(null);
    useEffect(() => {
        const btns = document.querySelectorAll(".acc-btn");
        function accordion() {
            // this = the btn | icon & bg changed
            this.classList.toggle("is-open");

            // the acc-content
            const content = this.nextElementSibling;

            // IF open, close | else open
            if (content.style.maxHeight) content.style.maxHeight = null;
            else content.style.maxHeight = content.scrollHeight + "px";
        }

        // event
        btns.forEach((el) => el.addEventListener("click", accordion));
    }, []);
    // faq section js write here end
    // modal form open and close section js start
    useEffect(() => {
        var modals = document.getElementById("myModal");

        // Get the button that opens the modal
        var btn = document.getElementById("myBtn");


        // Get the <span> element that closes the modal
        var span = document.getElementsByClassName("close")[0];

        // When the user clicks the button, open the modal
        btn.onclick = function () {
            modals.style.display = "block";
        }

        // When the user clicks on <span> (x), close the modal
        span.onclick = function () {
            modals.style.display = "none";
        }

        // When the user clicks anywhere outside of the modal, close it
        window.onclick = function (event) {
            if (event.target == modals) {
                modals.style.display = "none";
            }
        }

    }, [])
    // faq section js write here end
   
    return (

        <div>
            <MetaTags>
                <title>Hassle-Free Claim Assistance Service for Every Asset of Your Loved Ones - DigiWill</title>
                <meta name="description" content=" Claiming an asset of your loved one can be a tricky process, but we can help you along the way. We are here to make your claim smooth and hassle-free. Get in touch with us today!" />
            </MetaTags>


            {/* <Popper></Popper> */}

            <LoadingBar
                color='#f11946'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <section class="app-soon-wrapper" id="app-soon-screen">
                <div class="container px-5">
                    <div class="row gx-5   text-center">
                        <div class="col-lg-6">
                            <div className='d-flex flex-column app-soon-left-wrapper mt-5' >
                                <div className='mb-4 '>
                                    <h1>Inheritance <br />For Everyone.</h1>
                                </div>
                                <div className='mb-4'>
                                    <p>Record all your assets like stocks, mutual funds, real estate, cryptocurrency
                                        & bank investments, and nominate your loved ones
                                        to enjoy hassle-free distribution of your assets in 1 click.</p>
                                </div>
                                <div className='justify-content-center flex-column '>
                                    <button className='btn btn-success join-btn mb-4' id="myBtn">Join Our Waiting List</button>
                                    <div id="myModal" class="modals">
                                        <div class="modal-contents">
                                            <div class="">
                                                <div class="d-flex flex-row justify-content-between align-items-center text-center mb-3">
                                                    <h4 class="text-center ">Fill this form & we will get in touch with you.</h4>
                                                    <span class="close">&times;</span>

                                                </div>

                                                <form class="row g-3 form-register" onSubmit={(e) => handleRegister(e)}>
                                                    <div class="form-floating  col-12 col-lg-6">
                                                        <input type="text" pattern="^[A-Za-z -]+$" required  value={registerData.full_name} onChange={(e) => handleChange(e)} name="full_name"  class="form-control" id="floatingInput" placeholder="First Name" />
                                                        <label for="floatingName">Full Name</label>
                                                    </div>
                                                    <div class="form-floating  col-12 col-lg-6">
                                                        <input pattern="[0-9]{10}" required value={registerData.mobile_no} onChange={(e) => handleChange(e)} name="mobile_no" type="tel" class="form-control" id="floatingInput"
                                                            placeholder="Phone Number" />
                                                        <label for="floatingNumber">Phone Number</label>
                                                    </div>
                                                    <div class="form-floating col-12 col-lg-6">
                                                        <input size="30" value={registerData.email} required onChange={(e) => handleChange(e)} name="email" type="email" class="form-control" id="floatingInput"
                                                            placeholder="name@example.com" />
                                                        <label for="floatingInput">Email address</label>
                                                    </div>
                                                   
                                                    <div class="form-floating  col-12 col-lg-6">
                                                        <input type="text" pattern="^[A-Za-z -]+$" required value={registerData.city} onChange={(e) => handleChange(e)} name="city"  class="form-control" id="floatingInput" placeholder="City" />
                                                        <label for="floatingLname">City</label>
                                                    </div>
                                                   
                                                    <div class="col-12 col-lg-12 text-center">
                                                        {/* <Link className="dropdown-item" to="/thank-you"><button type="submit" class="btn-hover color-5">Submit</button></Link> */}
                                                        <button type="submit" class="btn-hover color-5">Submit</button>
                                                    </div>
                                                </form>
                                            </div>


                                        </div>

                                    </div>
                                    <h4 className='text-light'>App Launching Soon.</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" >
                            {/* Masthead device left contain */}
                            <div class="app-soon-device-mockup ">
                                <img src="./images/appsoon/App-UI-min.png"
                                    class=" " />

                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {/* Mashead section1 end */}



        </div>);
}

export default AppSoon;
