import React, { useEffect, useState, useNavigate, useContext } from 'react';

import { BASE_URLS, PLAYSTORE_URL } from './urls';
import { useAlert } from 'react-alert'
import { positions } from 'react-alert'
import ReactGA, { set } from 'react-ga';
import { TRACKING_CODE } from './urls';
import LoadingBar from 'react-top-loading-bar';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { AppContext } from '../../user/AppContext';
import ApiConfig from '../../api/ApiConfig';
import { PostCallWithErrorResponse } from '../../api/ApiServices';
import ReCAPTCHA from "react-google-recaptcha";

ReactGA.initialize(TRACKING_CODE);
function ContactGet() {
    const width = window.innerWidth
    const { show, setShow } = useContext(AppContext)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {

        if (width <= 972) {
            setShow('none')
        }
        else {
            setShow('flex')
        }

    }, [])
    useEffect(() => {
        ReactGA.event({
            category: 'get_in_touch',
            action: 'visited to contact page'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);

    }, [])

    useEffect(() => {
        ReactGA.event({
            category: 'request_call_back',
            action: 'visited to contact page'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);

    }, [])
    const [progress, setProgress] = useState(0)
    const [button1, setbutton1] = useState("Click here to Request call from us?");
    const [id, setid] = useState()
    const alert = useAlert()
    const [registerData, setRegisterData] = useState({ name: '', email: "", city: "", message: "" })
    const [registerData1, setRegisterData1] = useState({ name: '', phone_no: '' })
    const [verifed, setVerifed] = useState(false);

    function onChange(value) {
        console.log("Captcha value:", value);
        setVerifed(true);
    }
    useEffect(

        () => {
            setProgress(100)
            document.title = "contact-us-page - DigiWill-Keep The Track Record of Your Assets and Liabilities"
        }, []
    )
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const handleRegister = async (e) => {
        e.preventDefault()
        alert.show('Hi thank you for choosing us ! Our Representative will contact you shortly..', { position: positions.TOP_CENTER })
        PostCallWithErrorResponse(ApiConfig.GET_IN_TOUCH, registerData).then((data) => {
            console.log(data.json)
            if (data.json.status) {
                console.log("first")
                setRegisterData({ name: '', email: "", city: "", message: "" })
                setTimeout(() => {
                    setProgress(25)
                }, 100);
                setTimeout(() => {
                    setProgress(70)
                }, 100);
                setTimeout(() => {
                    setProgress(100)
                }, 100);
            }
        })
            .catch((error) => {
                console.log("api response", error);
            });
        ReactGA.event({
            category: 'User',
            action: 'contact get in touch Form'
        });

    }

    const handleRegister1 = async (e) => {
        e.preventDefault()

        alert.show('Hi thank you for choosing us ! Our Representative will contact you shortly..', { position: positions.TOP_CENTER })
        PostCallWithErrorResponse(ApiConfig.REQUEST_CALLBACK, registerData1).then((data) => {
            console.log(data.json)
            if (data.json.status) {

                setRegisterData1({ name: '', phone_no: '' })
                setTimeout(() => {
                    setProgress(25)
                }, 100);
                setTimeout(() => {
                    setProgress(70)
                }, 100);
                setTimeout(() => {
                    setProgress(100)
                }, 100);
            }
        })
            .catch((error) => {
                console.log("api response", error);
            });
        ReactGA.event({
            category: 'User',
            action: 'contact get in touch Form'
        });

    }

    const handleChange = (e) => {

        if (e.target.name === 'name') {

            setRegisterData({ ...registerData, name: e.target.value })
        }

        if (e.target.name === 'email') {

            setRegisterData({ ...registerData, email: e.target.value })
        }
        if (e.target.name === 'city') {

            setRegisterData({ ...registerData, city: e.target.value })
        }

        if (e.target.name === 'message') {

            setRegisterData({ ...registerData, message: e.target.value })
        }

    }

    const handleChange1 = (e) => {

        if (e.target.name === 'name') {

            setRegisterData1({ ...registerData1, name: e.target.value })
        }

        if (e.target.name === 'phone_no') {

            setRegisterData1({ ...registerData1, phone_no: e.target.value })
        }

    }

    const [isActive, setActive] = useState(true);

    const toggleClass = () => {
        setActive(!isActive);
    };

    useEffect(() => {
        function clearInp() {
            document.getElementsByTagName("input").value = "";
        }
    })

    return <div>
        <MetaTags>
            <title>Contact us | Get in Touch | Email us - DigiWill</title>
            <meta name="description" content=" If you have any queries related to our product or services, you can contact us by filling out the form or emailing us at info@yourdigiwill.com." />
        </MetaTags>
        <LoadingBar
            color='#f11946'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
        />
        <section id="cx-contact" className='' >
            <div className="container">
                <div className="cx-outer-contact">
                    <div className="cx-content-header">
                        <h2 className='mb-5'></h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-6" >
                            <div className=" cx-contact-left-wrapper ">
                                <div className='mb-5'>
                                    <img src="https://d2x7e1xt27q73s.cloudfront.net/contactvector.png" alt="contact-get-in-touch" />
                                </div>
                                <div className='cx-left-button m-4'>
                                    <button onClick={toggleClass} className=''>{isActive ? 'Click here to Request call from us?' : 'Get in Touch'}</button>
                                    <div className='cx-left-img '>
                                        <img src="https://d2x7e1xt27q73s.cloudfront.net/phone.png" alt="" className='phone-icon' />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            {isActive ? <div className='cx-contact-right-wrapper'>

                                <div>
                                    <h2 className='mb-3'>Get in <span>Touch</span> </h2>
                                    <p>You can reach us anytime via <span>info@yourdigiwill.com</span> </p>
                                </div>
                                <div className='contact-get-in-form'>
                                    <form onSubmit={(e) => handleRegister(e)} name="myForm">
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" class="form-label">Your Full Name</label>
                                            <input type="text" pattern="^[A-Za-z -]+$" maxlength="40" required value={registerData.name} onChange={(e) => handleChange(e)} name='name' className="form-control1" id="exampleInputName" />
                                        </div>

                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" class="form-label">Your Email</label>
                                            <input type="email" required value={registerData.email} onChange={(e) => handleChange(e)} name='email' className="form-control1" id="exampleInputEmail1" />
                                        </div>
                                        <div class="mb-3">
                                            <label for="exampleFormControlTextarea1" class="form-label">City</label>
                                            <input type="text" pattern="[A-Za-z]*" maxlength="20" required value={registerData.city} onChange={(e) => handleChange(e)} name='city' className="form-control1" id="exampleInputName" />
                                        </div>
                                        <div class="mb-3">
                                            <label for="exampleFormControlTextarea1" class="form-label">Message</label>
                                            <textarea type='text' maxlength="120" required class=" form-control form-control1" value={registerData.message} onChange={(e) => handleChange(e)} name='message' id="exampleFormControlTextarea1" rows="2"></textarea>
                                        </div>
                                        <div className="captcha" style={{ transform: "scale(0.88)", transformOrigin: "0 0" }}>
                                            <ReCAPTCHA
                                                sitekey="6LfPk6MjAAAAAILoHwcOKf-55xEIYFxc9tyWQzsw"
                                                onChange={onChange}
                                            />
                                        </div>
                                        <button type="submit" className=" btn btn-success contact-btn" disabled={!verifed}>Send Message</button>
                                    </form>
                                </div>
                            </div> : <div className='cx-contact-right-wrapper'>
                                <div>
                                    <h2 className='mb-3 '><span>Request </span>Call Back</h2>
                                    <p>You can reach us anytime via <span>info@yourdigiwill.com</span> </p>
                                </div>
                                <div className='contact-get-in-form'>
                                    <form onSubmit={(e) => handleRegister1(e)}  >
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" class="form-label">Your Full Name</label>
                                            <input type="text" pattern="[A-Za-z]*" maxlength="40" required value={registerData1.name} onChange={(e) => handleChange1(e)} name='name' className="form-control1" id="exampleInputName" />
                                        </div>

                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" class="form-label">Phone Number</label>
                                            <input type="tel" pattern="[0-9]{10}" required value={registerData1.phone_no} onChange={(e) => handleChange1(e)} name='phone_no' className="form-control1" id="exampleInputEmail1" />
                                        </div>
                                        <div className="captcha" style={{ transform: "scale(0.88)", transformOrigin: "0 0" }}>
                                            <ReCAPTCHA
                                                sitekey="6LfPk6MjAAAAAILoHwcOKf-55xEIYFxc9tyWQzsw"
                                                onChange={onChange}
                                            />
                                        </div>
                                        <button type="submit" className=" btn btn-success contact-btn" disabled={!verifed}>Request Call Back</button>
                                    </form>
                                </div>
                            </div>}

                        </div>
                        {/* <div className="col-lg-6">
                            <div className='cx-contact-right-wrapper'>
                                <div>
                                    <h2 className='mb-3 fs-1'>Request Call back</h2>
                                    <p>You can reach us anytime via <span>info@DigiWill.com</span> </p>
                                </div>
                                <div className='contact-get-in-form'>
                                    <form onSubmit={(e) => handleRegister(e)}  >
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" class="form-label">Your Full Name</label>
                                            <input type="text" required value={registerData.your_full_name} onChange={(e) => handleChange(e)} name='your_full_name' className="form-control1" id="exampleInputName" />
                                        </div>

                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" class="form-label">Phone Number</label>
                                            <input type="text" required value={registerData.phone_number} name='phone_number' className="form-control1" id="exampleInputEmail1" />
                                        </div>
                                        <button type="submit" className=" btn btn-success contact-btn">Request Call Back</button>
                                    </form>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>
        </section>

        <section id="cxp-download">
                    <div className="container">
                        <div className="cxp-outer">
                            <div className="cxp-download-content">
                                <div className="cxp-download-btn">
                                    {/* <a href="#" style={{marginRight:"8rem"}}><img src="./images/home/google_play.png" alt='optional' /></a> */}
                                    {/*      <a href="#"><img src="./images/home/google_play.png" /></a> */}
                                </div>
                                <h4>Download India's Only One-Stop Financial & Non-Financial Asset Inheritance Solution.</h4>
                                <div >
                                    <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" className="cx-banner-btns"><img src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/google-play-badge.png" style={{ height: "65.79px", width: "170px" }} alt="DigiWill App - Get it on Google Play Store" /></a>
                                    {/*    <a href="#"><img src="images/home/app_store.png" /></a> */}


                                </div>
                            </div>
                        </div>
                    </div>
                </section>



    </div>;
}

export default ContactGet;
