import React,{useState,useEffect} from 'react';

import Popper from '../Popper';

import LoadingBar from 'react-top-loading-bar'
import FooterEnsurance from './FooterEnsurance';
import ReactGA from 'react-ga';
import { TRACKING_CODE } from './urls';
ReactGA.initialize(TRACKING_CODE);
function CurosityBox() {

    const [progress, setProgress] = useState(0)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(()=>{
        ReactGA.event({
            category: 'User',
            action: 'visited Curosity box page'
          });
          ReactGA.pageview(window.location.pathname + window.location.search);
      },[])

    useEffect(

        ()=>{
            setProgress(100)
        },[]
    )
  return (<div>



{/* 
<Popper></Popper> */}


    <LoadingBar
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />  
<section id="cxb-phead">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className='cxb-head-content'>
                    <h2>Curiosity Box</h2>
                    <p>Real Life Useful Cases</p>
                </div>
            </div>
        </div>
    </div>
</section>







 <section id="cxb-fin">
    <div className="container">
        <div className="cxb-outer01">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className='cxb-content'>
                        <div className="cxb-content-header">
                            <h2>Your Financial Life </h2>
                            <p>All your worldly possessions on the go :</p>
                        </div>
                        <ul>
                            <li><img src="./images/ebooks/bullet_tick_pink.svg"  alt='optional'/>Download DigiWill App</li>
                            <li><img src="./images/ebooks/bullet_tick_pink.svg"  alt='optional'/> Sign up</li>
                            <li><img src="./images/ebooks/bullet_tick_pink.svg"  alt='optional'/> Complete Profile</li>
                            <li><img src="./images/ebooks/bullet_tick_pink.svg"  alt='optional'/> Add Nominees and Family Members</li>
                            <li><img src="./images/ebooks/bullet_tick_pink.svg"  alt='optional'/> Add Unlimited Assets of all types </li>
                            <li><img src="./images/ebooks/bullet_tick_pink.svg"  alt='optional'/> Use Real Time Asset Tracking System</li>
                            <li><img src="./images/ebooks/bullet_tick_pink.svg"  alt='optional'/> To share all asset details in real time</li>
                            <li><img src="./images/ebooks/bullet_tick_pink.svg"  alt='optional'/> To notify your loved ones about your DigiWill</li>
                            <li><img src="./images/ebooks/bullet_tick_pink.svg"  alt='optional'/> Relax you just connected your financial life with your family.</li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className='cxb-content d-flex justify-content-center'>
                        <img className="cx-ebook-image" src="./images/ebooks/ebook_01.png" alt='optional' />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section id="cxb-real">
    <div className="container">
        <div className="cxb-outer02">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className='cxb-content d-flex justify-content-center'>
                        <img src="./images/ebooks/ebook_02.png"   alt='optional imahe' />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className='cxb-content'>
                        <div className="cxb-content-header">
                            <h2>Real Life Use Case
                                </h2>
                            <p>Assurance of never losing your supportive documents 
                                for investments, assets, savings or liabilities</p>
                        </div>
                        <ul>
                            <li><img src="./images/ebooks/bullet_tick_green.svg"  alt='optional imahe'/>Organized Track Record of all your assets, 
                                investments, savings and liabilities
                                
                               
                                </li>
                            <li><img src="./images/ebooks/bullet_tick_green.svg" alt='optional imahe' />  Record Creation of any investment, asset,
                                liability or saving on the go with DigiWill App</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>




 <section id="cxb-after">
    <div className="container">
        <div className="cxb-outer03">
            <div className="row align-items-center">
                <div className="col-lg-12">
                    <div className='cxb-content'>
                        <div className="cxb-content-header">
                            <h2>After Life Use Case</h2>
                            <p>Single point nominee integration for all your assets,
                                investments, savings & laibilities</p>
                        </div>
                        <ul>
                            <li>
                                <h4>DigiWill is a pioneer & one of its kind</h4>
                                <p>Solution which empowers individuals to connect their financial life with their family ensuring no assets remain unclaimed.
                                    </p>
                            </li>
                            <li>
                                <h4>After You</h4>
                                <p>DigiWill hand holds your family to get what's rightfully theirs                                   </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section id="cxb-inherit">
    <div className="container">
        <div className="cxb-outer04">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className='cxb-content d-flex justify-content-center'>
                        <img src="./images/ebooks/ebook_02.png" alt='optional' />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className='cxb-content'>
                        <div className="cxb-content-header">
                            <h2>Inheritance Guaranteed</h2>
                            <p> Our Soul Objective : Secure & Rightfull Inheritance</p>
                        </div>
                        <ul>
                            <li>
                                <p>Single or multiple nominees/family members ensures rightful claims. </p>
                            </li>
                            <li>
                                 <h4>Memories : You Presence for your family after you</h4>
                                 <p>A virtual arena of significant moments of your life stored and delivered to your loved ones
                                     Store life moments in Video, Audio, Photo, Text messages formats
                                     </p>
                             </li>
                             <li>
                                 <h4>All Asset Claim Support Assurance</h4>
                                 <p>Rest Assured, we deliver door step end to end claim support for your loved ones.                                    </p>
                             </li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</section>


 <section id="cxb-digi">
    <div className="container">
        <div className="cxb-outer01">
            <div className="row align-items-center">
                <div className="col-lg-12">
                    <div className='cxb-content'>
                        <div className="cxb-content-header">
                            <h2>Your DigiWill page</h2>
                            <p>DigiWill is that family member that shows up when everyone else turns their backs, it keeps the family updated with the earning members and families investments, assets, savings and liabilities and hand holds the widowed, dependents and kids to get what`s rightfully theirs.</p>
                        </div>
                        <ul>
                            <li>
                                <p>The idea of DigiWill was conceived when our founder Ajinkya Thakur got the news that he is going to be a father, he was celebrating and insecure at the same time as he had a lot to leave behind for his loved ones. He observed that there is no solution which will ensure your loved ones get what you have earned and kept for them. 
                                    </p>
                            </li>
                            <li>
                                <p>It was the summer of 2021, he gathered the team and started working out and DigiWill was born.</p>
                            </li>
                            <li>
                                <p>We are a bunch of finance experts and technological geeks, obsessed to provide the best solution to ensure your loved ones get what is rightfully theirs.</p>
                            </li>
                            <li>
                                <p>If there was a solution for this there wouldn't have been 1 lakh 24 thousand crores of unclaimed money lying in India with public and private financial institutions ( as per the hindu dec 2020 data).

                                    We have seen families get devastated, loved ones suffer to get what is rightfully theirs and our obsession to change that, brings us down to DigiWill.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


 <section id="cxb-ensure">
    <div className="container">
        <div className="cxb-outer02">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className='cxb-content d-flex justify-content-center'>
                        <img src="./images/ebooks/ebook_02.png" alt='otptional' />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className='cxb-content'>
                        <div className="cxb-content-header">
                            <h2>DigiWill`s Ensurance page
                                </h2>
                            <p>Help us help you and your loved ones </p>
                        </div>
                        <ul>
                            <li><img src="./images/ebooks/bullet_tick_green.svg"  alt='optional'/>We serve you and your loved ones after you, 
                                
                               
                                </li>
                            <li><img src="./images/ebooks/bullet_tick_green.svg" alt='optional' />  We assure DigiWill`s doorstep hand holding support to your widowed, dependants and kids to claim all your assets, investments and savings.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section id="cxb-nom">
    <div className="container">
        <div className="cxb-outer03">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className='cxb-content'>
                        <div className="cxb-content-header">
                            <h2> Your Nominees Page  </h2>
                        </div>
                        <ul>
                            <li><img src="./images/ebooks/bullet_tick_pink.svg"  alt='optional'/>Add single or multiple nominees</li>
                            <li><img src="./images/ebooks/bullet_tick_pink.svg"  alt='optional'/> Add their contact details to ensure communication</li>
                            <li><img src="./images/ebooks/bullet_tick_pink.svg"  alt='optional'/> Use Timely Record Tracking communication regarding your DigiWill OR add them to Real time Asset Tracking </li>
                            <li><img src="./images/ebooks/bullet_tick_pink.svg" alt='optional' /> Use both or single features </li>
                            <li><img src="./images/ebooks/bullet_tick_pink.svg"  alt='optional'/> Edit/Update/Add/Remove Nominees and their contact information right from your phone. </li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className='cxb-content d-flex justify-content-center'>
                        <img className="cx-ebook-image" src="./images/ebooks/ebook_01.png" alt='optional' />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section id="cxb-use">
    <div className="container">
        <div className="cxb-outer04">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className='cxb-content d-flex justify-content-center'>
                        <img src="./images/ebooks/ebook_02.png"  alt='optional'/>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className='cxb-content'>
                        <div className="cxb-content-header">
                            <h2>For the user page
                                </h2>
                            <p>Life is uncertain, but you have DigiWill.</p>
                        </div>
                        <ul>
                            <li><img src="./images/ebooks/bullet_tick_green.svg" alt='optional'/>DigiWill`s Linked account Feature provides easy and organized access to documents like health records, insurance policies, identity documents, etc  to the user and their family in case of emergencies like accidents, pandemics and unforeseen life events.
                                
                               
                                </li>
                            <li><img src="./images/ebooks/bullet_tick_green.svg" alt='optional'/> Track Record of all the investments, savings, assets and liabilities in one place makes it easier to calculate one's net worth and access credit facilities on the go.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

 <section id="cxb-fam">
    <div className="container">
        <div className="cxb-outer01">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className='cxb-content'>
                        <div className="cxb-content-header">
                            <h2> Family/Nominees/Loved ones use page   </h2>
                            <p>Assured Inheritance with DigiWill </p>
                        </div>
                        <ul>
                            <li><img src="./images/ebooks/bullet_tick_pink.svg"  alt='optional'/>DigiWill`s Service Manager will hand hold your widowed, dependants, kids and ensure that all your assets, investments and savings are claimed by them, 

                                We provide end to end doorstep service and complete all formalities & required documentation with the help of our renowned and professional service partners so that your loved ones don`t have to struggle to get what is rightfully theirs.</li>
                            <li><img src="./images/ebooks/bullet_tick_pink.svg" alt='optional'/> We are proud to say that our users designate us as a FAMILY MEMBER, A PROFESSIONAL and an EXPERT and let us serve their spouses, kids and dependents attaining peace of mind for themselves. 

                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className='cxb-content d-flex justify-content-center'>
                        <img className="cx-ebook-image" src="./images/ebooks/ebook_01.png" alt='optional'/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

 <section id="cxb-so">
    <div className="container">
        <div className="cxb-outer02">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className='cxb-content d-flex justify-content-center'>
                        <img src="./images/ebooks/ebook_02.png"  alt='optional'/>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className='cxb-content'>
                        <div className="cxb-content-header">
                            <h2>Societal Reality Page
                                </h2>
                            <p>Death is inevitable but the irony is there is zero financial inheritance awareness.

                            </p>
                        </div>
                        <ul>
                            <li><img src="./images/ebooks/bullet_tick_green.svg"  alt='optional'/>1 Lakh 24 Thousand Of Unclaimed Money as of Dec 2020 (as per the hindu)</li>
                            <li><img src="./images/ebooks/bullet_tick_green.svg"  alt='optional'/>Lack/Loss of records of investments, savings, assets </li>
                            <li><img src="./images/ebooks/bullet_tick_green.svg"  alt='optional'/> Lack of communication between family members regarding these records, if available</li>
                            <li><img src="./images/ebooks/bullet_tick_green.svg"  alt='optional'/>Exploitation of widowed, dependents and kids by our own or outsiders </li>
                            <li><img src="./images/ebooks/bullet_tick_green.svg" alt='optional' />Hesitation of family heads/earning members to share financial details with the remaining family members. </li>
                            <li><img src="./images/ebooks/bullet_tick_green.svg"  alt='optional'/>Lack of trust keeps spouses and family members in dark regarding the earning members financial life.</li>
                            <li><img src="./images/ebooks/bullet_tick_green.svg"  alt='optional'/>Unorganized documents</li>
                            <li><img src="./images/ebooks/bullet_tick_green.svg"  alt='optional'/>Everyone wants to invest, save and build assets but no one wants to inform their family members about them. </li>
                            <li><img src="./images/ebooks/bullet_tick_green.svg" alt='optional' />In return, widows, kids and dependents have to face exploitation and are deprived of their rightful claims.</li>
                            <li><img src="./images/ebooks/bullet_tick_green.svg" alt='optional' />In return, widows, kids and dependents have to face exploitation and are deprived of their rightful claims.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="cxb-mi">
    <div className="container">
        <div className="cxb-outer03">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className='cxb-content'>
                        <div className="cxb-content-header">
                            <h2> The Change and Challenger Page </h2>
                            <p>We have one mission. To help curb the rise of unclaimed money in India and globally </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className='cxb-content d-flex justify-content-center'>
                        <img className="cx-ebook-image" src="./images/ebooks/ebook_01.png" alt='optional' />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<FooterEnsurance/>


  </div>);
}

export default CurosityBox;
