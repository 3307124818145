import React, { useState, useEffect,useContext } from 'react';

import Popper from '../Popper';
import FooterEnsurance from './FooterEnsurance';
import MetaTags from 'react-meta-tags';
import { AppContext } from '../../user/AppContext';

function Faq() {
  const width = window.innerWidth
    const {show,setShow}=useContext(AppContext)
    useEffect(() => {

        if (width <= 972) {
          setShow('none')
        }
        else{
          setShow('flex')
        }
    
      }, [])
      useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
  return <div>
    <MetaTags>
      <title>FAQs | App Related Questions | Process Related Questions</title>
      <meta name="description" content="Get to know the Frequently Asked Questions about the DigiWill App and the different processes involved." />
    </MetaTags>

   
    {/* <Popper></Popper> */}


    <section id="cxf-banner">
      <div className="container">
        <div clas="row " style={{ height: "100%" }}>
          <div className="col-12 d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
            <div className="cxf-banner-content">
              <span>Help Center</span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="cxf-faq">
      <div className="container">
        <div className="cxf-faq-content">
          <h2>About DigiWill</h2>
          <div className="accordion" id="cxf-accordion-one">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  1.	What is DigiWill?
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#cxf-accordion-one">
                <div className="accordion-body">
                  <p>DigiWill is the 21st-century-way of maintaining records of your assets and liabilities for your family. DigiWill is the best app to make sure your assets reach your loved ones. Forget old red books with tons of papers. With DigiWill, you can maintain your financial records without any paper-related hassle for you or your nominees.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  2.	What makes DigiWill unique?
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#cxf-accordion-one">
                <div className="accordion-body">
                  DigiWill is a mobile application (available on Android and iOS). It is an end-to-end encrypted app which helps you maintain detailed records of all your assets, savings, and liabilities.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  3.	What can you do with DigiWill?
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-one">
                <div className="accordion-body">
                  You can keep a track record of all your assets and liabilities with DigiWill App. You can store information about your assets and liabilities, which can be Insurance Policies, Startup Investments, Bonds, ETFs, NFTs, Equity Investments, Crypto Currencies, Real Estate, Loans, Credit Cards, Mutual Funds, Bank Accounts Post Office Investments, Retirement Funds,  ESOPs, etc.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                  4.	Why should I choose DigiWill over the physical form of will?
                </button>
              </h2>
              <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-one">
                <div className="accordion-body">
                  <p>DigiWill is the 21st century way of passing your lifelong earnings to your family. Gone are the days when you had to manage a whole book of your financial records. DigiWill provides you with a platform where you can upload all your financial information without fearing the loss of records or exposing your information to anyone.</p>

                  <p>To make sure your wishes reach your family, DigiWill eliminates the need for physical consultation, drafting, or meetings to formulate a traditional physical will. We make sure your loved ones get what you have earned for them, without any paper-related hassle.</p>

                  <p> We also provide a ready-to-print, download-draft-via-email “self-express physical will”. It comes with pre-filled details about your assets, which are obtained from your DigiWill application in just a few clicks. However, you won't need a physical will if you simply use a DigiWill!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cxf-faq-content">
          <h2>App Related Questions</h2>
          <div className="accordion" id="cxf-accordion-Two">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseOne">
                  5.	How to create a DigiWill account?
                </button>
              </h2>
              <div id="collapseFive" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#cxf-accordion-Two">
                <div className="accordion-body">
                  You can create a DigiWill account by signing up on our app.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingSix">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseTwo">
                  6.	What details do I need to create a DigiWill account?
                </button>
              </h2>
              <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#cxf-accordion-Two">
                <div className="accordion-body">
                  You only need basic details such as name, email, and mobile number.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingSeven">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseThree">
                  7.	What information can I store in my account?
                </button>
              </h2>
              <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-Two">
                <div className="accordion-body">
                  You can store any information about all your assets, liabilities and can also store your significant life moments in the form of videos, photos, audios, and letters.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingEight">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseThree">
                  8.	In what formats can I store information in my account?
                </button>
              </h2>
              <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-Two">
                <div className="accordion-body">
                  DigiWill is an encrypted storage app for all your financial documents. We support audio, video, documents, pdfs, photos, and text formats.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingNine">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseThree">
                  9.	How do you ensure the safety of my details?
                </button>
              </h2>
              <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-Two">
                <div className="accordion-body">
                  Every media uploaded on DigiWill is end-to-end encrypted. The security protocols are such that even employees of DigiWill cannot see your details.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTen">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseThree">
                  10.	Can I change the details in my DigiWill account?
                </button>
              </h2>
              <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-Two">
                <div className="accordion-body">
                  <p>You can easily change details regarding your assets, liabilities, investments, savings, and nominees in your DigiWill account with just a few taps on your phone.</p>
                  <p>In normal circumstances, you would need to visit the designated location (banks, offices) and stand in long queues even if you wanted to change a detail as simple as a phone number. With DigiWill, you can change any of your details in just one click.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cxf-faq-content">
          <h2>Process Related Questions</h2>
          <div className="accordion" id="cxf-accordion-three">
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading11">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="true" aria-controls="collapseOne">
                  11.	Is my DigiWill legal?
                </button>
              </h2>
              <div id="collapse11" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#cxf-accordion-three">
                <div className="accordion-body">
                  Yes, DigiWill is legal.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading12">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse12" aria-expanded="false" aria-controls="collapseTwo">
                  12.	How does DigiWill verify my identity?
                </button>
              </h2>
              <div id="collapse12" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#cxf-accordion-three">
                <div className="accordion-body">
                  We verify your details through the Government Digi Locker. Our KYC procedure requires you to confirm your Digilocker account. You can verify your identity without stepping out of your home. (Note: DigiWill and DigiLocker Integration is still in progress)
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading13">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse13" aria-expanded="false" aria-controls="collapseThree">
                  13.	How does my information reach my family members?
                </button>
              </h2>
              <div id="collapse13" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-three">
                <div className="accordion-body">
                  You can share real-time financial information with your family using DigiWill’s Communication Feature. And you can share timely financial information notifications using DigiWill’s Communication Feature
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading14">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse14" aria-expanded="false" aria-controls="collapseThree">
                  14.	Can I add nominees to my assets?
                </button>
              </h2>
              <div id="collapse14" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-three">
                <div className="accordion-body">
                  Yes, you can.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading15">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse15" aria-expanded="false" aria-controls="collapseThree">
                  15.	Can I add a nominee or multiple nominees to my DigiWill?
                </button>
              </h2>
              <div id="collapse15" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-three">
                <div className="accordion-body">
                  Yes
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading16">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16" aria-expanded="false" aria-controls="collapseThree">
                  16.	How many nominees can I add to a single asset?
                </button>
              </h2>
              <div id="collapse16" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-three">
                <div className="accordion-body">
                  You can add single or multiple nominees to a single asset.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading17">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse17" aria-expanded="false" aria-controls="collapseThree">
                  17.	What details do I need to add a nominee?
                </button>
              </h2>
              <div id="collapse17" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-three">
                <div className="accordion-body">
                  You need their basic details (name, age,  relation, etc) plus their email and phone number.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading18">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse18" aria-expanded="false" aria-controls="collapseThree">
                  18.	How will the nominee be notified?
                </button>
              </h2>
              <div id="collapse18" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-three">
                <div className="accordion-body">
                  We will send them Communication  via email and phone number, informing them they have been added as a nominee to your DigiWill. If you want, you can choose not to notify or select who to notify. You have complete control over the notifications sent from your account.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading19">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse19" aria-expanded="false" aria-controls="collapseThree">
                  19.	Will my family members need to have a DigiWill account to see my DigiWill details?
                </button>
              </h2>
              <div id="collapse19" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-three">
                <div className="accordion-body">
                  Yes, your family members will need to have a DigiWill account if they want to see the details of your assets and liabilities.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading20">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse20" aria-expanded="false" aria-controls="collapseThree">
                  20.	Can I share my DigiWill details with family members who are not my nominees?
                </button>
              </h2>
              <div id="collapse20" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-three">
                <div className="accordion-body">
                  Yes. You can share your DigiWill details with family members even if they are not the nominee.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading21">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse21" aria-expanded="false" aria-controls="collapseThree">
                  21.	How does DigiWill keep my family members informed?
                </button>
              </h2>
              <div id="collapse21" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-three">
                <div className="accordion-body">
                  We send them Communication and Communication according to your choice of frequency. We will also send them physical letters if you opt-in for that (available in our Ensurance Subscription Plan)
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading22">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse22" aria-expanded="false" aria-controls="collapseThree">
                  22.	Can I assign the same person as a nominee to different assets?
                </button>
              </h2>
              <div id="collapse22" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-three">
                <div className="accordion-body">
                  Yes, you can do that.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading23">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse23" aria-expanded="false" aria-controls="collapseThree">
                  23.	How much data can I store in my account?
                </button>
              </h2>
              <div id="collapse23" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-three">
                <div className="accordion-body">
                  You can store 2GB worth of data for free. More data is provided in the premium plan.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading24">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse24" aria-expanded="false" aria-controls="collapseThree">
                  24.	Are there any charges for using DigiWill?
                </button>
              </h2>
              <div id="collapse24" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-three">
                <div className="accordion-body">
                  No, you can use DigiWill for free. But we provide extra benefits to our Ensurance Plan Holders which costs INR 3650/year (INR 10/day).
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading25">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse25" aria-expanded="false" aria-controls="collapseThree">
                  25.	How many family members can I add to my DigiWill account?
                </button>
              </h2>
              <div id="collapse25" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-three">
                <div className="accordion-body">
                  There is no limit. You can add as many family members as you like.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading26">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse26" aria-expanded="false" aria-controls="collapseThree">
                  26.	How do I delete my DigiWill account?
                </button>
              </h2>
              <div id="collapse26" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-three">
                <div className="accordion-body">
                  Currently, there is no in-app feature to delete your DigiWill account. If you want to delete your account, please reach out to us at support@yourDigiWill.com
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="cxf-faq-content">
          <h2>Miscellaneous Questions</h2>
          <div className="accordion" id="cxf-accordion-four">
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading27e">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse27" aria-expanded="true" aria-controls="collapseOne">
                  27.	Are there any rewards linked to using DigiWill?
                </button>
              </h2>
              <div id="collapse27" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#cxf-accordion-four">
                <div className="accordion-body">
                  Yes. As a reward, we issue Digicoins to all DigiWill users. We reward you because you have taken a big step towards ensuring a secure financial future for your family.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading28">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse28" aria-expanded="false" aria-controls="collapseTwo">
                  28.	Where can I claim my Digi Coins?
                </button>
              </h2>
              <div id="collapse28" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#cxf-accordion-four">
                <div className="accordion-body">
                  You can claim your Digi Coins on our application.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading29">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse29" aria-expanded="false" aria-controls="collapseThree">
                  29.	How can I redeem my Digicoins?
                </button>
              </h2>
              <div id="collapse29" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-four">
                <div className="accordion-body">
                  You can redeem your Digicoins for cash back, discounts, and in-app purchases. There are several uses of Digicoin which will be released soon. Digicoin possesses an intrinsic value, so, hold on to your Digicoins!
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading30">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse30" aria-expanded="false" aria-controls="collapseThree">
                  30.	How do I know my information will stay secure?
                </button>
              </h2>
              <div id="collapse30" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-four">
                <div className="accordion-body">
                  DigiWill is an end-to-end encrypted modern age application. We use industry-standard security to ensure your details never get compromised.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading31">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse31" aria-expanded="false" aria-controls="collapseThree">
                  31.	What countries is DigiWill available in?
                </button>
              </h2>
              <div id="collapse31" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#cxf-accordion-four">
                <div className="accordion-body">
                  Currently, DigiWill is only available in India.
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2 className="cxf-reach">How can I contact DigiWill?</h2>
        <p className="cxf-preach">You can reach out to us on email info@yourdigiwill.com or support@yourDigiWill.com</p>
      </div>
    </section>


    <FooterEnsurance />



  </div>;
}

export default Faq;
