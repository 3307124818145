import React from 'react'

const FooterApp = () => {
    return (
        <div>



            <div class="container py-5 text-center" id='footer-app'>
                <div class="row">
                    <div class="col-lg-12 ">
                        <div class="lc-block mb-4">
                            <a class="navbar-brand app-logo" href="#"><img src='https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/Dw+white+logo.png' /></a>
                        </div>
                        {/* <div class="lc-block mb-4" >
                            <div editable="rich">
                                <h6 className='text-light fs-5'>Digiwill</h6>
                            </div>
                        </div> */}
                        <div class="lc-block mb-4" >
                            <div editable="rich">
                                <p className='btn-footer '>Crafted with Love in India</p>
                            </div>
                        </div>
                        <div class="lc-block mb-4">
                            <div editable="rich d-flex flex-column align-items-center ">
                                <h5 className='text-light p-0 m-0'> Download Our App</h5>
                                <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank"><img src='https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/google-play-badge.png' style={{ width: "220px",objectFit:"contain" }}></img></a>
                            </div>
                        </div>
                        {/* <div class="lc-block mb-4">
                            <div editable="rich">
                                <p className='text-light'>We are a new kid on the block, eager to learn.<br />
                                    All ideas and suggestions are welcome.</p>
                            </div>
                        </div> */}
                        <div class="lc-block mb-4" >
                            <div editable="rich">
                                <h6 className='text-light fs-5'>Our Partners</h6>
                            </div>
                        </div>
                        {/* <div class="lc-block mb-5  " >
                            <div className='rich footer-partner-logo d-flex flex-row justify-content-center align-items-center'>
                               <img className='mx-3' src='./images/plogostatic/razarpay2.png'></img>
                               <img src='./images/plogostatic/aws2.png'></img>
                               
                            </div>
                        </div> */}
                         <div class="lc-block mb-5  " >
                        <div className='rich d-flex flex-row justify-content-evenly flex-wrap cx-partner-logo'>
                        <img src='https://d2x7e1xt27q73s.cloudfront.net/razarpay2.png' style={{ width: "100px", objectFit: "contain" }}></img>
                            <img src='https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/aws.png' style={{ width: "100px", objectFit: "contain" }}></img>
                            <img src='https://d2x7e1xt27q73s.cloudfront.net/fego.png' style={{ width: "100px", objectFit: "contain" }}></img>
                            <img src='https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/surepass.png' style={{ width: "100px", objectFit: "contain" }}></img>
                            <img src='https://d2x7e1xt27q73s.cloudfront.net/Equifax.png' style={{ width: "100px", objectFit: "contain" }}></img>
                            <img src='https://d2x7e1xt27q73s.cloudfront.net/Edelweiss.png' style={{ width: "100px", objectFit: "contain" }}></img>
                        </div>
                    </div>
                        <div class="lc-block mt-5">
                            <div editable="rich ">
                                <h6 className='text-light'> Copyright &#169;2022 Servfar Services Pvt Ltd</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default FooterApp
