
import React, { useState, useEffect } from 'react';
import Popper from '../Popper';
import LoadingBar from 'react-top-loading-bar'
import { PLAYSTORE_URL } from './urls';
import FooterEnsurance from './FooterEnsurance';
import ReactGA from 'react-ga';
import { TRACKING_CODE } from './urls';
import MetaTags from 'react-meta-tags';
ReactGA.initialize(TRACKING_CODE);
function BePreparedCard() {
    const [progress, setProgress] = useState(0)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(

        () => {
            setProgress(100)
        }, []
    )
    useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'visited Invest page'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
        document.title = "Invest - DigiWill-Keep The Track Record of Your Assets and Liabilities"
    }, [])
    return (<div>
        <MetaTags>
            <title></title>
            <meta name="description" content="Invest, record, grow, and transmit all your finances all at one place on the go with Invest in Feature from DigiWill." />
        </MetaTags>

        {/* 
          <Popper></Popper> */}

        <LoadingBar
            color='#f11946'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
        />

        <section id="cx-bepreparednew" className='' >
            <div className="container cx-outer-bepreparednew">
                <div className='row'>
                    <div className='col-lg-4 d-flex align-items-center'>
                        <div className='cx-first-wrapper'>
                            <div class="card-body d-flex align-items-center ">
                                <div className='cx-card-content-left'>
                                    <h5 class="card-title">Ai Asset Discovery</h5>
                                    <p class="card-text">Auto Discover your financial & non-financial assets like shares, mutual funds, stocks, bonds, crypto, real-estate, digital and physical gold, etc.</p>
                                </div>

                                <img src="./images/prepare/card1.png" />
                            </div>
                            <div class="card-body d-flex align-items-center ">

                                <div className='cx-card-content-left'>
                                    <h5 class="card-title">Central Nomination</h5>
                                    <p class="card-text">Nominate your loved ones across all investments, savings, nps, kitty parties, esops, startup investments from one single platform.</p>
                                </div>
                                <img src="./images/prepare/card2.png" />
                            </div>
                            <div class="card-body d-flex align-items-center ">

                                <div className='cx-card-content-left'>
                                    <h5 class="card-title">Legally Valid</h5>
                                    <p class="card-text">Created & crafted by industry experts and domian experienced legal advisors.</p>
                                </div>
                                <img src="./images/prepare/card3.png" />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 '>
                        <div className='cx-img-beprepared d-flex justify-content-center'>
                            <img src='./images/prepare/mobile.png'></img>
                        </div>
                    </div>
                    <div className='col-lg-4 d-flex align-items-center'>
                        <div  className='cx-first-wrapper'>
                        <div class="card-body d-flex align-items-center">
                            <img src="./images/prepare/card4.png" />
                            <div className='cx-card-content-right'>
                                <h5 class="card-title">Nominee Discovery</h5>
                                <p class="card-text">Discover your nominees with a single click.</p>
                            </div>
                        </div>
                        <div class="card-body d-flex align-items-center">
                            <img src="./images/prepare/card5.png" />
                            <div className='cx-card-content-right'>
                                <h5 class="card-title">Automated Communications</h5>
                                <p class="card-text">Setup Tech based real-time and future event based human interference free communication of financial records & Will’s.</p>
                            </div>

                        </div>
                        <div class="card-body d-flex align-items-center">
                            <img src="./images/prepare/card6.png" />
                            <div className='cx-card-content-right'>
                                <h5 class="card-title">No Human Interferance</h5>
                                <p class="card-text">Eliminates retaining fees, tampering, biased presentation & pre-deceased complexities</p>
                            </div>

                        </div>
                        </div>
                        
                    </div>
                </div>



            </div>
        </section>


    </div>);
}

export default BePreparedCard;
