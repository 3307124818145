import React, { useState, useEffect } from 'react';

import Popper from '../Popper';
import LoadingBar from 'react-top-loading-bar'
import { PLAYSTORE_URL } from './urls';
import FooterEnsurance from './FooterEnsurance';
import ReactGA from 'react-ga';
import { TRACKING_CODE } from './urls';
import MetaTags from 'react-meta-tags';
ReactGA.initialize(TRACKING_CODE);
function Invest() {
    const [progress, setProgress] = useState(0)

    useEffect(

        () => {
            setProgress(100)
        }, []
    )
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'visited Invest page'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
        document.title = "Invest - DigiWill-Keep The Track Record of Your Assets and Liabilities"
    }, [])
    return (<div>
        {/* <MetaTags>
            <title> Invest in | A One-Stop Financial Market Place - DigiWill</title>
            <meta name="description" content="Invest, record, grow, and transmit all your finances all at one place on the go with Invest in Feature from DigiWill." />
        </MetaTags> */}

        {/* 
<Popper></Popper> */}

        <LoadingBar
            color='#f11946'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
        />
        <section id="cxi-hero">
            <div className="cxi-hero-o d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="cxi-hero-content">
                                <h2>Invest, record, grow, transmit your
                                    finance all at one place on the go</h2>
                                <p>Get a chance with us to cater our customers
                                    & increase your business</p>
                                <div className="cx-btn-grp">
                                    <button type="button" className="cx-btn cx-btn-border">Coming Soon</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="cxi-ivest">
            <div className="cxi-outer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="cxi-invest-content cxi-bg-01">
                                <span>Investments</span>
                                <h2>No More...</h2>
                                <p>Using multiple platforms for different investments</p>
                                <div className="cxi-invest-img">
                                    <img src="./images/invest/coming_soon.png" alt="invest" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="cxi-invest-content cxi-bg-02">
                                <span>Investments</span>
                                <h2>No More...</h2>
                                <p>Using multiple platforms for different investments</p>
                                <div className="cxi-invest-img">
                                    <img src="./images/invest/coming_soon.png" alt="invest" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="cxi-invest-content cxi-bg-03">
                                <span>Investments</span>
                                <h2>No More...</h2>
                                <p>Using multiple platforms for different investments</p>
                                <div className="cxi-invest-img">
                                    <img src="./images/invest/coming_soon.png" alt="invest" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section id="cxi-download">
            <div className="container">
                <div className="cxi-outer" style={{ paddingTop: 0 }}>
                    <div className="cxi-download-content">
                        <div className="cxi-download-btn">
                            {/* <a href={PLAYSTORE_URL} style={{marginRight:"8 rem"}}><img src="./images/home/google_play.png"  alt='optional'/></a> */}
                            {/*         <a href="#"><img src="./images/home/google_play.png" /></a> */}
                        </div>
                        <h4>Download the most secure online document storage app for android and iOS devices now!</h4>
                    </div>
                </div>
            </div>
        </section>

        <FooterEnsurance />



    </div>);
}

export default Invest;
