import React, { useEffect, useState } from 'react';
import Popper from '../Popper';
import { BASE_URLS, PLAYSTORE_URL } from './urls';
import { useAlert } from 'react-alert'
import { positions } from 'react-alert'
import ReactGA from 'react-ga';
import { TRACKING_CODE } from './urls';
import LoadingBar from 'react-top-loading-bar';
import FooterEnsurance from './FooterEnsurance';
import MetaTags from 'react-meta-tags';
import { PostCallWithErrorResponse } from '../../api/ApiServices';
import ApiConfig from '../../api/ApiConfig';
import ReCAPTCHA from "react-google-recaptcha";

ReactGA.initialize(TRACKING_CODE);
function ServicePartner() {
    const [progress, setProgress] = useState(0)
    const alert = useAlert()
    const [registerData, setRegisterData] = useState({ name: '', email: "", phone_number: "", city: "", profession: "" })
    const [verifed, setVerifed] = useState(false);

    function onChange(value) {
        console.log("Captcha value:", value);
        setVerifed(true);
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'visited service partner page'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
        document.title = "Service-partner - DigiWill-Keep The Track Record of Your Assets and Liabilities"
    }, [])

    useEffect(
        () => {
            setProgress(100)
        }, []
    )

    const handleRegister = async (e) => {
        e.preventDefault()
        alert.show('Hi thank you for choosing us ! Our Representative will contact you shortly..', { position: positions.TOP_CENTER })

        PostCallWithErrorResponse(ApiConfig.SERVICE_PARTNER, registerData).then((data) => {
            console.log(data.json)
            if (data.json.status) {
                console.log("first")
                setRegisterData({ name: '', email: "", phone_number: "", city: "", profession: "" })
                setTimeout(() => {
                    setProgress(25)
                }, 100);
                setTimeout(() => {
                    setProgress(70)
                }, 100);
                setTimeout(() => {
                    setProgress(100)
                }, 100);
            }
        })
            .catch((error) => {
                console.log("api response", error);
            });
        ReactGA.event({
            category: 'User',
            action: 'Service partner contact form'
        });



    }

    const handleChange = (e) => {

        if (e.target.name === 'name') {

            setRegisterData({ ...registerData, name: e.target.value })
        }

        if (e.target.name === 'email') {

            setRegisterData({ ...registerData, email: e.target.value })
        }

        if (e.target.name === 'phone_number') {

            setRegisterData({ ...registerData, phone_number: e.target.value })
        }

        if (e.target.name === 'city') {

            setRegisterData({ ...registerData, city: e.target.value })
        }
        if (e.target.name === 'profession') {

            setRegisterData({ ...registerData, profession: e.target.value })
        }

    }

    return (
        <div>
            <MetaTags>
                <title> Become our Service Partner|DigiWill</title>
                <meta name="description" content="Get a chance to be our Service Partner to increase your business. Fill out the form to get in touch with us!
." />
            </MetaTags>
            {/* <Popper></Popper> */}

            <LoadingBar
                color='#f11946'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <main>



                <section id="cxp-hero">
                    <div className="cxp-hero-o d-flex align-items-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="cxp-hero-content">
                                        <h2>Become our Service Partner</h2>
                                        <p>Get a chance with us to cater our customers
                                            & increase your business</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="cxp-entails">
                    <div className="cxp-outer">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="cxp-content-header">
                                        <h2>What Entails for you?</h2>
                                    </div>
                                    <div className="cxp-entails-content">
                                        <ul className="cxp-elist">
                                            <li>
                                                - Work with us: A unique opportunity to work with India’s First and Only Asset Claim Guaranteeing Company.
                                            </li>
                                            <li>
                                                - Early Bird Access: Early access to a place to showcase your services to our users on our Platform & Early access to be a part of the first of its kind financial ecosystem.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="cxp-entails-content">
                                        <div className="cxp-eform">
                                            <h2>Lets Partner up!</h2>
                                            <p>Fill in the below form and we’ll reach out to you</p>
                                            <form onSubmit={(e) => handleRegister(e)} >
                                                <div className="mb-3">
                                                    <input type="text" pattern="^[A-Za-z -]+$" maxlength="38" size="40" required value={registerData.name} onChange={(e) => handleChange(e)} name='name' className="form-control" id="exampleInputEmail1" placeholder="Name" />
                                                </div>
                                                <div className="mb-3">
                                                    <input type="tel" pattern="[0-9]{10}" required value={registerData.phone_number} onChange={(e) => handleChange(e)} name='phone_number' className="form-control" id="exampleInputEmail1" placeholder="Phone Num" />
                                                </div>
                                                <div className="mb-3">
                                                    <input type="email" size="30" required value={registerData.email} onChange={(e) => handleChange(e)} name='email' className="form-control" id="exampleInputEmail1" placeholder="Email" />
                                                </div>
                                                {/*         <div className="mb-3">
                                        <select name='city' required value={registerData.city}  onChange={(e)=>handleChange(e)}  className="form-select form-control" aria-label="Default select example">
                                            <option selected>City</option>
                                            <option value="1">Mumbai</option>
                                            <option value="2">Pune</option>
                                        </select>
                                    </div> */}
                                                <div className="mb-3">
                                                    <input name='city' pattern="^[A-Za-z -]+$" size="40" required value={registerData.city} placeholder="City" onChange={(e) => handleChange(e)} className=" form-control" aria-label="Default select example" />

                                                </div>
                                                {/*   <div className="mb-3">
                                        <select name='profession' required value={registerData.profession}  onChange={(e)=>handleChange(e)}  className="form-select form-control" aria-label="Default select example">
                                            <option selected>Profession</option>
                                            <option value="Lawyer">Lawyer</option>
                                            <option value="2">Two</option>
                                        </select>
                                    </div> */}
                                                <div className="mb-3">
                                                    <input name='profession' pattern="^[A-Za-z -]+$" required placeholder='Profession' value={registerData.profession} onChange={(e) => handleChange(e)} className="form-control" aria-label="Default select example" />
                                                </div>
                                                <div className='mb-3 d-flex flex-row justify-content-center'>
                                                    <div className="captcha" style={{ transform: "scale(0.80)" }}>
                                                        <ReCAPTCHA
                                                            sitekey="6LfPk6MjAAAAAILoHwcOKf-55xEIYFxc9tyWQzsw"
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </div>
                                                <button type="submit" className="cx-btn" disabled={!verifed}>Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="cxp-download">
                    <div className="container">
                        <div className="cxp-outer">
                            <div className="cxp-download-content">
                                <div className="cxp-download-btn">
                                    {/* <a href="#" style={{marginRight:"8rem"}}><img src="./images/home/google_play.png" alt='optional' /></a> */}
                                    {/*      <a href="#"><img src="./images/home/google_play.png" /></a> */}
                                </div>
                                <h4>Download India's Only One-Stop Financial & Non-Financial Asset Inheritance Solution.</h4>
                                <div >
                                        <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" className="cx-banner-btns"><img src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/google-play-badge.png"  style={{ height: "65.79px", width: "170px" }} alt="DigiWill App - Get it on Google Play Store" /></a>
                                            {/*    <a href="#"><img src="images/home/app_store.png" /></a> */}
                                        
                    
                                        </div>
                            </div>
                        </div>
                    </div>
                </section>
                <FooterEnsurance />


            </main>
        </div>



    );
}

export default ServicePartner;
