import React, { useState } from "react";
import { AppContext } from "./AppContext";

export const AppState = (props) => {
  const [isCustomer, setisCustomer] = useState(true);
  const [show, setShow] = useState("flex")
  const [first,setFirst]=useState(true)

  return (
    <AppContext.Provider
      value={{
        setShow,
        show,
        first,setFirst
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
