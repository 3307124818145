import React, { useEffect, useState, useContext } from 'react';

import Popper from '../Popper';
import { BASE_URLS, PLAYSTORE_URL, TRACKING_CODE } from './urls';
import { useAlert } from 'react-alert'
import { positions } from 'react-alert'
import LoadingBar from 'react-top-loading-bar'
import FooterEnsurance from './FooterEnsurance';
import ReactGA from 'react-ga';
import MetaTags from 'react-meta-tags';
import { AppContext } from '../../user/AppContext';
import { PostCallWithErrorResponse } from '../../api/ApiServices';
import ApiConfig from '../../api/ApiConfig';
import ReCAPTCHA from "react-google-recaptcha";
ReactGA.initialize(TRACKING_CODE);
function ChannelPartner() {
    const width = window.innerWidth
    const { show, setShow } = useContext(AppContext);
    const [progress, setProgress] = useState(0)
    const alert = useAlert()
    const [registerData, setRegisterData] = useState({ name: '', email: "", phone_number: "", city: "", profession: "" })
    //const [success,setsuccess]=useState(false)
    const [verifed, setVerifed] = useState(false);
    function onChange(value) {
        console.log("Captcha value:", value);
        setVerifed(true);
    }
    useEffect(() => {

        if (width <= 972) {
            setShow('none')
        }
        else {
            setShow('flex')
        }

    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'visited Channel partner page'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    useEffect(

        () => {
            setProgress(100)
            document.title = "Channel-Partner - DigiWill-Keep The Track Record of Your Assets and Liabilities"
        }, []
    )
    
    const handleRegister = async (e) => {
        e.preventDefault()
        alert.show('Hi thank you for choosing us ! Our Representative will contact you shortly..', { position: positions.TOP_CENTER })

        PostCallWithErrorResponse(ApiConfig.CHANNEL_PARTNER, registerData).then((data) => {
            console.log(data.json)
            if (data.json.status) {
                console.log("first")
                setRegisterData({ name: '', email: "", phone_number: "", city: "", profession: "" })
                setTimeout(() => {
                    setProgress(25)
                }, 100);
                setTimeout(() => {
                    setProgress(70)
                }, 100);
                setTimeout(() => {
                    setProgress(100)
                }, 100);
            }
        })
            .catch((error) => {
                console.log("api response", error);
            });
        ReactGA.event({
            category: 'User',
            action: 'channel partner contact form'
        });


    }

    const handleChange = (e) => {

        if (e.target.name === 'name') {

            setRegisterData({ ...registerData, name: e.target.value })
        }

        if (e.target.name === 'email') {

            setRegisterData({ ...registerData, email: e.target.value })
        }

        if (e.target.name === 'phone_number') {

            setRegisterData({ ...registerData, phone_number: e.target.value })
        }

        if (e.target.name === 'city') {

            setRegisterData({ ...registerData, city: e.target.value })
        }
        if (e.target.name === 'profession') {

            setRegisterData({ ...registerData, profession: e.target.value })
        }

    }




    return (<div>
        <MetaTags>
            <title>Become our Channel Partner | DigiWill</title>
            <meta name="description" content="A unique opportunity to work with India’s First and Only Asset Claim Guaranteeing Company. Become our Channel partner now!" />
        </MetaTags>


        {/* <Popper></Popper> */}

        <LoadingBar
            color='#f11946'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
        />
        <section id="cxc-hero">
            <div className="cxc-hero-o d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="cxc-hero-content">
                                <h2 >Become our Channel Partner</h2>
                                <p>Get a chance with us to cater our customers
                                    & increase your business</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="cxc-entails">
            <div className="cxc-outer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="cxc-content-header">
                                <h2>What Entails for you?</h2>
                            </div>
                            <div className="cxc-entails-content">
                                <h4>Here’s what it entails:</h4>
                                <ul className="cxc-elist">
                                    <li>
                                        Work with us: A unique opportunity to work with India’s First and Only Asset Claim Guaranteeing Company.
                                    </li>
                                    <li>
                                        Early Bird Access: Early access to a place to showcase your services to our users on our Platform & Early access to be a part of the first of its kind financial ecosystem.
                                    </li>
                                    <li>
                                        Promotions: Promotion of products and services / Exchange of Leads & Cross-Platform Promotion for each other’s products/services.
                                    </li>
                                    <li>
                                        Revenue generation: DigiWill is going to share a part of revenue as referral fees/commissions/margin with its partners for promoting DigiWill`s services to their clients.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="cxc-entails-content">
                                <div className="cxc-eform">
                                    <h2>Lets Partner up!</h2>
                                    <p>Fill in the below form and we’ll reach out to you</p>
                                    <form onSubmit={(e) => handleRegister(e)} >
                                        <div className="mb-3">
                                            <input type="text" pattern="^[A-Za-z -]+$" maxlength="38" size="40" required value={registerData.name} onChange={(e) => handleChange(e)} name='name' className="form-control" id="exampleInputEmail1" placeholder="Name" />
                                        </div>
                                        <div className="mb-3">
                                            <input type="tel" pattern="[0-9]{10}" required value={registerData.phone_number} onChange={(e) => handleChange(e)} name='phone_number' className="form-control" id="exampleInputEmail1" placeholder="Phone Num" />
                                        </div>
                                        <div className="mb-3">
                                            <input type="email" size="30" required value={registerData.email} onChange={(e) => handleChange(e)} name='email' className="form-control" id="exampleInputEmail1" placeholder="Email" />
                                        </div>
                                        {/*      <div className="mb-3">
                                        <select name='city' required value={registerData.city}  onChange={(e)=>handleChange(e)}  className="form-select form-control" aria-label="Default select example">
                                            <option selected>City</option>
                                            <option value="1">Mumbai</option>
                                            <option value="2">Pune</option>
                                        </select>
                                    </div> */}
                                        <div className="mb-3">
                                            <input name='city' pattern="^[A-Za-z -]+$" size="40" required value={registerData.city} placeholder="City" onChange={(e) => handleChange(e)} className=" form-control" aria-label="Default select example" />

                                        </div>
                                        {/*  <div className="mb-3">
                                        <select name='profession' required value={registerData.profession}  onChange={(e)=>handleChange(e)}  className="form-select form-control" aria-label="Default select example">
                                            <option selected>Profession</option>
                                            <option value="Lawyer">Lawyer</option>
                                            <option value="2">Two</option>
                                        </select>
                                    </div> */}
                                        <div className="mb-3">
                                            <input name='profession' pattern="^[A-Za-z -]+$" placeholder='Profession' required value={registerData.profession} onChange={(e) => handleChange(e)} className="form-control" aria-label="Default select example" />
                                        </div>
                                        <div className='mb-3 d-flex flex-row justify-content-center'>
                                            <div className="captcha" style={{ transform: "scale(0.80)" }}>
                                                <ReCAPTCHA
                                                    sitekey="6LfPk6MjAAAAAILoHwcOKf-55xEIYFxc9tyWQzsw"
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                        <button type="submit" className="cx-btn" disabled={!verifed}>Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <section id="cxc-download">
        <div className="container">
            <div className="cxc-outer">
                <div className="cxc-download-content">
                    <div className="cxc-download-btn">
                        <a href={PLAYSTORE_URL} style={{marginRight:"8rem"}}><img src="./images/home/google_play.png"  alt='optional'/></a>
                        <a href="#"><img src="./images/home/google_play.png" alt"optional" /></a>
                    </div>
                    <h4>Download the most secure online document storage app for android device now!</h4>
                </div>
            </div>
        </div>
    </section> */}

        <section id="cxp-download">
            <div className="container">
                <div className="cxp-outer">
                    <div className="cxp-download-content">
                        <div className="cxp-download-btn">
                            {/* <a href="#" style={{marginRight:"8rem"}}><img src="./images/home/google_play.png" alt='optional' /></a> */}
                            {/*      <a href="#"><img src="./images/home/google_play.png" /></a> */}
                        </div>
                        <h4>Download India's Only One-Stop Financial & Non-Financial Asset Inheritance Solution.</h4>
                        <div >
                            <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" className="cx-banner-btns"><img src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/google-play-badge.png" style={{ height: "65.79px", width: "170px" }} alt="DigiWill App - Get it on Google Play Store" /></a>
                            {/*    <a href="#"><img src="images/home/app_store.png" /></a> */}


                        </div>
                    </div>
                </div>
            </div>
        </section>

        <FooterEnsurance />






    </div>);
}

export default ChannelPartner;
