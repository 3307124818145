
import React, { useState, useRef } from 'react'
import ApiConfig from '../../api/ApiConfig';
import Multiselect from 'multiselect-react-dropdown';
import { postWithAuthCallWithErrorResponse } from '../../api/ApiServices';
import { event } from 'react-ga';
import swal from 'sweetalert';
const B2bCompanyDeatils = () => {
    const [selectData, setSelectData] = useState("");
    const [progress, setProgress] = useState(0);
    const [registerData, setRegisterData] = useState({ company_name:'', entity_type:'', files:'' })
    const [file, setFile] = useState();

    const handelFile = (e) => {
        setFile(e.target.files[0])
       // console.log(e.target.files[0])
    }

    const handleRegister = async (e) => {
        e.preventDefault();
        if (registerData.company_name == "") {
            swal({
                title: "Alert",
                text: "please enter first name!",
                icon: "warning",
            });
            return
        }
        if (registerData.entity_type == "") {
            swal({
                title: "Alert",
                text: "please enter last name!",
                icon: "warning",
            });
            return
        } 

        const body = new FormData()
        body.append('company_name', registerData.company_name)
        body.append('entity_type', registerData.entity_type)
        body.append('files', file)
        console.log(body)


        const response = await fetch(ApiConfig.UPLOAD_DOCUMENT, {
            method: "POST",
            headers: {
                Accept: "application/json",
                    Authorization: "Token " + localStorage.getItem("token"),
            },
            body: body
        })

        const res = await response.json()
        console.log(res)
        if (res.status) {
            swal({
                title: "Done",
                text: res.message,
                icon: "success",
            });
            localStorage.setItem('token', res.token)
            
        }
        else {
            swal({
                title: "Error",
                text: res.message,
                icon: "warning",
            });
        }

    }
    const handleChange = (e) => {
        if (e.target.name === 'company_name') {

            setRegisterData({ ...registerData, company_name: e.target.value })
        }
        if (e.target.name === 'entity_type') {

            setRegisterData({ ...registerData, entity_type: e.target.value })
        }

        if (e.target.name === 'files') {

            setRegisterData({ ...registerData, files: e.target.value })
        }

    }


    return (
        <>
            <nav class="sb-topnav navbar navbar-expand navbar-dark bg-dark">
                {/* <!-- Navbar Brand--> */}
                <a class="navbar-brand ps-3" href="index.html"><img src="https://d2x7e1xt27q73s.cloudfront.net/whitee.png" alt="" style={{ width: "70px" }} /></a>
                {/* <!-- Sidebar Toggle--> */}
                <button class="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!"><i class="fas fa-bars"></i></button>
                {/* <!-- Navbar Search--> */}
                <form class="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                    <div class="input-group">
                        <input class="form-control" type="text" placeholder="Search for..." aria-label="Search for..." aria-describedby="btnNavbarSearch" />
                        <button class="btn btn-primary" id="btnNavbarSearch" type="button"><i class="fas fa-search"></i></button>
                    </div>
                </form>
                {/* <!-- Navbar--> */}
                <ul class="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-user fa-fw"></i></a>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                            <li><a class="dropdown-item" href="#!">Settings</a></li>
                            <li><a class="dropdown-item" href="#!">Activity Log</a></li>
                            <li><hr class="dropdown-divider" /></li>
                            <li><a class="dropdown-item" href="#!">Logout</a></li>
                        </ul>
                    </li>
                </ul>
            </nav>
            <div id="layoutSidenav">
                <div id="layoutSidenav_nav">
                    <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                        <div class="sb-sidenav-menu">
                            <div class="nav">
                                <div class="sb-sidenav-menu-heading">Core</div>
                                <a class="nav-link" href="index.html">
                                    <div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i></div>
                                    Dashboard
                                </a>
                                <div class="sb-sidenav-menu-heading">Interface</div>
                                <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
                                    <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                                    Layouts
                                    <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                                </a>
                                <div class="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                    <nav class="sb-sidenav-menu-nested nav">
                                        <a class="nav-link" href="layout-static.html">Static Navigation</a>
                                        <a class="nav-link" href="layout-sidenav-light.html">Light Sidenav</a>
                                    </nav>
                                </div>
                                <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
                                    <div class="sb-nav-link-icon"><i class="fas fa-book-open"></i></div>
                                    Pages
                                    <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                                </a>
                                <div class="collapse" id="collapsePages" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
                                    <nav class="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionPages">
                                        <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseAuth" aria-expanded="false" aria-controls="pagesCollapseAuth">
                                            Authentication
                                            <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                                        </a>
                                        <div class="collapse" id="pagesCollapseAuth" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                            <nav class="sb-sidenav-menu-nested nav">
                                                <a class="nav-link" href="login.html">Login</a>
                                                <a class="nav-link" href="register.html">Register</a>
                                                <a class="nav-link" href="password.html">Forgot Password</a>
                                            </nav>
                                        </div>
                                        <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseError" aria-expanded="false" aria-controls="pagesCollapseError">
                                            Error
                                            <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                                        </a>
                                        <div class="collapse" id="pagesCollapseError" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                            <nav class="sb-sidenav-menu-nested nav">
                                                <a class="nav-link" href="401.html">401 Page</a>
                                                <a class="nav-link" href="404.html">404 Page</a>
                                                <a class="nav-link" href="500.html">500 Page</a>
                                            </nav>
                                        </div>
                                    </nav>
                                </div>
                                <div class="sb-sidenav-menu-heading">Addons</div>
                                <a class="nav-link" href="charts.html">
                                    <div class="sb-nav-link-icon"><i class="fas fa-chart-area"></i></div>
                                    Charts
                                </a>
                                <a class="nav-link" href="tables.html">
                                    <div class="sb-nav-link-icon"><i class="fas fa-table"></i></div>
                                    Tables
                                </a>
                            </div>
                        </div>
                        <div class="sb-sidenav-footer">
                            <div class="small">Logged in as:</div>
                            Start Bootstrap
                        </div>
                    </nav>
                </div>
                <div id="layoutSidenav_content">
                    <main>
                        <div class="container-fluid px-4">
                            <h1 class="mt-4">Enter Company details</h1>
                            <ol class="breadcrumb mb-4">
                                <li class="breadcrumb-item active">Dashboard</li>
                            </ol>
                            <div class="row first-section-wrapper" >
                                <div class="col-md-5 mb-3">
                                    <label for="validationCustom01" class="form-label mb-2" >Company Name </label>
                                    <input type="text" name='company_name' value={registerData.company_name} onChange={(e) => handleChange(e)} class="form-control company-input" id="validationCustom01" placeholder='Tata Motors' required />

                                </div>
                                <div class="col-md-5">
                                    <label for="validationDefault04" class="form-label mb-2">Entity Type</label>
                                    <select value={registerData.entity_type} onChange={(e) => handleChange(e)} name='entity_type' for='validationDefault04' class="form-select company-input" id="validationDefault04" required>
                                        <option selected  >Please Select</option>
                                        <option value="SOLE PROPRIETORSHIP FIRMS">SOLE PROPRIETORSHIP FIRMS</option>
                                        <option value="PARTNERSHIP FIRMS">PARTNERSHIP FIRMS</option>
                                        <option value="LIMITED LIABILITY PARTNERSHIP (LLP)">LIMITED LIABILITY PARTNERSHIP (LLP)</option>
                                        <option value="COMPANIES">COMPANIES</option>
                                        <option value="TRUSTS/ASSOCIATION/CLUB/SOCIETY">TRUSTS/ASSOCIATION/CLUB/SOCIETY</option>
                                        <option value="HUF">HUF</option>
                                    </select>

                                </div>
                            </div>

                            {/* <div class="row mt-4 ">
                                <h2>Attach Documents here</h2>

                                <div className='col-lg-5 border mb-4  mx-3'>
                                    <label class="btn btn-default btn-sm center-block btn-file d-flex flex-row justify-content-end">

                                        <i class="fa fa-paperclip fa-2x" aria-hidden="true"></i>
                                        <input type="file" style={{ display: "none" }} />
                                    </label>
                                </div>
                                <div className='col-lg-5 border mb-4 mx-3'>
                                    <label class="btn btn-default btn-sm center-block btn-file d-flex flex-row justify-content-end">
                                        <i class="fa fa-paperclip fa-2x" aria-hidden="true"></i>
                                        <input type="file" style={{ display: "none" }} />
                                    </label>
                                </div>

                            </div> */}
                            <div className='row'>
                                {
                                    registerData.entity_type == "SOLE PROPRIETORSHIP FIRMS" ?
                                        <div>
                                            <div>
                                                <h3 className='mt-3'>Document List</h3>
                                                <ul>
                                                    <li>1.Registration certificate </li>
                                                    <li>2.Certificate/licence issued by the municipal authorities under Shop and Establishment Act.</li>
                                                    <li>3.Sales and income tax returns.</li>
                                                    <li>4.CST/VAT/GST certificate (provisional/final).</li>
                                                    <li>5.Certificate/registration document issued by Sales Tax/Service Tax /Professional Tax authorities</li>
                                                    <li>6.IEC (Importer Exporter Code) issued to the proprietary concern by the office of DGFT/
                                                        Licence/certificate of practice issued in the name of the proprietary concern by any
                                                        professional body incorporated under a statute.</li>
                                                    <li>7.Complete Income Tax Return (not just the acknowledgement) in the name of the sole
                                                        proprietor where the firm's income is reflected, duly authenticated/acknowledged by the
                                                        Income Tax authorities.</li>
                                                    <li>8.Utility bills such as electricity, water, and landline telephone bills (not older than two
                                                        months).</li>
                                                </ul>
                                                <h4 className='mt-3 mb-3'>Please Select Above Document</h4>
                                            </div>
                                            <div className='col-lg-10 col-12 border mb-4 mx-1'>
                                                <label class="btn btn-default btn-sm center-block btn-file d-flex flex-row justify-content-end">
                                                    <i class="fa fa-paperclip fa-2x text-success" aria-hidden="true"></i>
                                                    <input type="file" id="formFileMultiple" multiple  onChange={(e) => handelFile(e)}  />

                                                </label>
                                            </div>
                                        </div> : ""
                                }
                                {/* {
                                    selectData == "SOLE PROPRIETORSHIP FIRMS" ? <div id='test'>
                                        <div className='col-lg-5 border mb-4 mx-3'>
                                            <label class="btn btn-default btn-sm center-block btn-file d-flex flex-row justify-content-end">
                                                <i class="fa fa-paperclip fa-2x" aria-hidden="true"></i>
                                                <input type="file" id="formFileMultiple" multiple style={{ display: "none" }} />
                                            </label>
                                        </div>
                                    </div> : ""
                                } */}
                                {
                                    registerData.entity_type == "PARTNERSHIP FIRMS" ?
                                        <div>
                                            <div>
                                                <h3 className='mt-3'>Document List</h3>
                                                <ul>
                                                    <li>1.Copy of Registration Certificate, if the firm is registered. </li>
                                                    <li>2.Copy of PAN of the Partnership firm.</li>
                                                    <li>3.Copy of Partnership Deed.</li>
                                                    <li>4.Power of Attorney granted to a partner of an employee of the firm to transact business
                                                        on its behalf.</li>
                                                    <li>5.Copies of proof of identify and proof of address along with PAN of the main partners and
                                                        persons holding the PoA.</li>
                                                    <li>6.Copy of Proof of Legal name, telephone number of the firm and partners apart from the
                                                        above.</li>
                                                </ul>
                                                <h4 className='mt-3 mb-3'>Please Select Above Document</h4>
                                            </div>
                                            <div className='col-lg-10 border mb-4 mx-1'>
                                                <label class="btn btn-default btn-sm center-block btn-file d-flex flex-row justify-content-end">
                                                    <i class="fa fa-paperclip fa-2x text-success" aria-hidden="true"></i>
                                                    <input type="file" id="formFileMultiple" multiple style={{ display: "none" }} />
                                                </label>
                                            </div>
                                        </div> : ""
                                }
                                {
                                    registerData.entity_type == "LIMITED LIABILITY PARTNERSHIP (LLP)" ?
                                        <div>
                                            <div>
                                                <h3 className='mt-3'>Document List</h3>
                                                <ul>
                                                    <li>1.Copy of the Certificate of Incorporation (mentioning LLPIN) document and DPIN of the
                                                        designated partners. </li>
                                                    <li>2.Copy of the LLP agreement.</li>
                                                    <li>3.Copy of PAN of LLP.</li>
                                                    <li>4.Copies of proof of identify and proof of address along with PAN of the designated
                                                        partners and persons holding the PoA.</li>
                                                    <li>5.Copy of the Resolution to open an account and list of authorized person/s with the
                                                        specimen signatures to operate the account duly attested by Designated Partners.</li>
                                                </ul>
                                                <h4 className='mt-3 mb-3'>Please Select Above Document</h4>
                                            </div>
                                            <div className='col-lg-10 border mb-4 mx-1'>
                                                <label class="btn btn-default btn-sm center-block btn-file d-flex flex-row justify-content-end">
                                                    <i class="fa fa-paperclip fa-2x text-success" aria-hidden="true"></i>
                                                    <input type="file" id="formFileMultiple" multiple style={{ display: "none" }} />
                                                </label>
                                            </div>
                                        </div> : ""
                                }
                                {
                                    registerData.entity_type == "COMPANIES" ?
                                        <div>
                                            <div>
                                                <h3 className='mt-3'>Document List</h3>
                                                <ul>
                                                    <li>1.Copy of Certificate of incorporation (mentioning CIN). </li>
                                                    <li>2.Copy of Memorandum & Articles of Association.</li>
                                                    <li>3.Copy of PAN of the Company.</li>
                                                    <li>4.Resolution of the Board of Directors to open an account and list of officials authorized to
                                                        operate the account.</li>
                                                    <li>5.Identification of authorized signatories should be based on photographs and signature
                                                        cards duly attested by the company.</li>

                                                    <li>6.Copies of proof of identify and proof of address along with PAN of managers, officers of
                                                        employees holding Power of Attorney to transact business on its behalf.</li>
                                                    <li>7.List of directors along with DIN and copy of Form 32 (if directors are different from AOA).</li>
                                                    <li>8.Certified true copy of Certificate of commencement of business (Public Limited
                                                        Company).</li>
                                                    <li>9.Copy of Proof of the name of the company, Principal place of business, mailing address
                                                        of the company, Telephone/Fax number apart from the above. (Telephone bill not older
                                                        than two months).</li>
                                                </ul>
                                                <h4 className='mt-3 mb-3'>Please Select Above Document</h4>
                                            </div>
                                            <div className='col-lg-10 border mb-4 mx-1'>
                                                <label class="btn btn-default btn-sm center-block btn-file d-flex flex-row justify-content-end">
                                                    <i class="fa fa-paperclip fa-2x text-success" aria-hidden="true"></i>
                                                    <input type="file" id="formFileMultiple" multiple style={{ display: "none" }} />
                                                </label>
                                            </div>
                                        </div> : ""
                                }
                                {
                                    registerData.entity_type == "TRUSTS/ASSOCIATION/CLUB/SOCIETY" ?
                                        <div>
                                            <div>
                                                <h3 className='mt-3'>Document List</h3>
                                                <ul>
                                                    <li>1.Certificate of Registration, if registered. </li>
                                                    <li>2.Copy of PAN of Trust / Association / Club / Society.</li>
                                                    <li>3.Power of Attorney granted to transact business on its behalf, if any.</li>
                                                    <li>4.Any document listing out the names and addresses of the trustees, sellers, beneficiaries,
                                                        and those holding power of Attorney, and other key officials involved in the day to day
                                                        management of the trust to the satisfaction of the bank.</li>
                                                    <li>5.Resolution of the managing body of the foundation.</li>

                                                    <li>6. Declaration of Trust/Bye Law of society/Bye-law of Association/Bye-law of club.</li>
                                                    <li>7.Attach the Proof of name and address of the founder, Manager/director and the
                                                        beneficiaries, telephone/fax number, Telephone bill, Utility bill apart from the above(bills
                                                        not older than two months).
                                                    </li>
                                                </ul>
                                                <h4 className='mt-3 mb-3'>Please Select Above Document</h4>
                                            </div>
                                            <div className='col-lg-10 border mb-4 mx-1'>
                                                <label class="btn btn-default btn-sm center-block btn-file d-flex flex-row justify-content-end">
                                                    <i class="fa fa-paperclip fa-2x text-success" aria-hidden="true"></i>
                                                    <input type="file" id="formFileMultiple" multiple style={{ display: "none" }} />
                                                </label>
                                            </div>
                                        </div> : ""
                                }
                                {
                                    registerData.entity_type == "HUF" ?
                                        <div>
                                            <div>
                                                <h3 className='mt-3'>Document List</h3>
                                                <ul>
                                                    <li>1.Copy of PAN of the HUF . </li>
                                                    <li>2.Copy of Identity and Address proof of the Kartha.</li>
                                                </ul>
                                                <h4 className='mt-3 mb-3'>Please Select Above Document</h4>
                                            </div>
                                            <div className='col-lg-10 border mb-4 mx-1'>
                                                <label class="btn btn-default btn-sm center-block btn-file d-flex flex-row justify-content-end">
                                                    <i class="fa fa-paperclip fa-2x text-success" aria-hidden="true"></i>
                                                    <input type="file" id="formFileMultiple" multiple style={{ display: "none" }} />
                                                </label>
                                            </div>
                                        </div> : ""
                                }



                            </div>
                            <div className='row'>
                                <div className='col-md-5 mt-3 mb-3' >
                                    <button className='btn btn-success' type='submit' onClick={(e) => [handleRegister(e)]} >Save & Submit</button>
                                </div>
                            </div>
                        </div>
                    </main>

                </div>
            </div>
        </>
    )
}

export default B2bCompanyDeatils;
