import React, { useState, useEffect, useContext } from 'react';
import Typewriter from 'typewriter-effect';
import { BASE_URLS } from './urls';
import { AppContext } from '../../user/AppContext';

function HowWork2() {
    const width = window.innerWidth
    const { show, setShow } = useContext(AppContext)
    useEffect(() => {

        if (width <= 972) {
            setShow('none')
        }
        else {
            setShow('flex')
        }

    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <section id="cx-how" className=''>
            <div className="container-fluid">
                <div className="cx-outer-how">
                    <div className="cx-content-header">
                        <h2> How DigiWill Works !</h2>
                        {/* <p class="line-1 anim-typewriter">We provide best-in-class investment nominee services so you can build the plane as you fly it.</p> */}
                        {/* <p className='text-center'> <Typewriter
                            options={{
                                strings: ['We provide best-in-class investment nominee services so you can build the plane as you fly it.'],
                                autoStart: true,
                                loop: true,
                                pauseFor: 300000,
                            }}
                        />
                        </p> */}
                    </div>

                    <div className="cx-content cx-content-how">
                        <div id="carouselExampleCaptions" className="carousel slide " data-bs-ride="carousel"  >
                            <div className="row align-items-center cara-main-wrapper">
                                <div className="col-lg-12 position-relative" >
                                    <div className='hr-line'></div>
                                    <div className="carousel-indicators cx-indicators d-flex flex-row justify-content-evenly">
                                        <div type="button" data-bs-target=" #carouselExampleCaptions" data-bs-slide-to="0" className=" stepper-step1 active" aria-current="true" aria-label="Slide 1">
                                            <span className='circle-box text-dark ' >1</span>
                                            <span className='text-box text-dark mt-1'>Ai<br></br> Asset Discovery</span>
                                        </div>
                                        <div type="button" data-bs-target=" #carouselExampleCaptions" data-bs-slide-to="1" className=" stepper-step1 " aria-label="Slide 2"  >
                                            <span className='circle-box text-dark' >2</span>
                                            <span className='text-box text-dark mt-1'>Communication of Records & Wills</span>
                                        </div>
                                        <div type="button" data-bs-target=" #carouselExampleCaptions" data-bs-slide-to="2" className=" stepper-step1 " aria-label="Slide 3">
                                            <span className='circle-box text-dark' >3</span>
                                            <span className='text-box text-dark mt-1'>Nomination of your Loved Ones</span>
                                        </div>
                                        <div type="button" data-bs-target=" #carouselExampleCaptions" data-bs-slide-to="3" className=" stepper-step1 " aria-label="Slide 4">
                                            <span className='circle-box text-dark' >4</span>
                                            <span className='text-box text-dark mt-1'>Asset Distribution & Redistribution</span>
                                        </div>
                                        <div type="button" data-bs-target=" #carouselExampleCaptions" data-bs-slide-to="4" className=" stepper-step1 " aria-label="Slide 4">
                                            <span className='circle-box text-dark' >5</span>
                                            <span className='text-box text-dark mt-1'>Auto<br></br> Will<br></br> Creation</span>
                                        </div>

                                        {/* <button type="button" data-bs-target=" #carouselExampleCaptions" data-bs-slide-to="0" className="cx-in-btn active" aria-current="true" aria-label="Slide 1">Step 1 </button>
                                        <button type="button" data-bs-target=" #carouselExampleCaptions" data-bs-slide-to="1" className="cx-in-btn " aria-label="Slide 2" >Step 2</button>
                                        <button type="button" data-bs-target=" #carouselExampleCaptions" data-bs-slide-to="2" className="cx-in-btn" aria-label="Slide 3">Step 3</button>
                                        <button type="button" data-bs-target=" #carouselExampleCaptions" data-bs-slide-to="3" className="cx-in-btn" aria-label="Slide 4">Step 4</button>
                                        <button type="button" data-bs-target="  #carouselExampleCaptions" data-bs-slide-to="4" className="cx-in-btn" aria-label="Slide 5">Step 5</button> */}
                                    </div>
                                </div>

                                <div className="col-lg-12 step1-bg" >
                                    <div className="carousel-inner" >
                                        <div className="carousel-item active " data-bs-interval="5000" >
                                            <div className="row align-items-center ">
                                                <div className="col-lg-6 cx-img-wrapper ">
                                                    <div className="cx-carousel-slide cx-first-img d-flex flex-row  ">
                                                        <img src="https://d2x7e1xt27q73s.cloudfront.net/step1F.png" className="" alt="AI Asset discovery" />
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 '>
                                                    <div className="content-text-step mt-3 ">
                                                        <h2>Ai Asset Discovery</h2>
                                                        <p>One click discovery of your <br />Financial and Non-Financial Assets.</p>
                                                        <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank"><button className='btn btn-success mb-4 '>Discover Your Assets</button></a>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <div className="carousel-item" data-bs-interval="5000"  >
                                            <div className="row align-items-center">
                                                <div className="col-lg-6  cx-img-wrapper">
                                                    <div className="cx-carousel-slide cx-first-img  d-flex flex-row mt-4 ">
                                                        <img src="https://d2x7e1xt27q73s.cloudfront.net/step2.png" className="" alt="Communication of records after you" />
                                                    </div>

                                                </div>
                                                <div className='col-lg-6 '>
                                                    <div className="content-text-step mt-3 ">
                                                        <h2>Communication of<br /> Records & Wills</h2>
                                                        <p>Real-time and future event based human interference<br />  free communication of financial records & wills.</p>
                                                        <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank"><button className='btn btn-success mb-4 '>Communicate Your Assets</button></a>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <div className="carousel-item" data-bs-interval="5000" >
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 cx-img-wrapper">
                                                    <div className="cx-carousel-slide  cx-first-img d-flex flex-row  ">
                                                        <img src="https://d2x7e1xt27q73s.cloudfront.net/step3.png" className="" alt="Nomination across assets" />
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 '>
                                                    <div className="content-text-step mt-3 ">
                                                        <h2>Nomination of your <br />loved ones</h2>
                                                        <p>Nominate one or multiple nominees to enjoy hassle & dispute free distribution of your assets.</p>
                                                        <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank"><button className='btn btn-success mb-4 '>Nominate Your Loved Ones</button></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item" data-bs-interval="5000"  >
                                            <div className="row align-items-center">
                                                <div className="col-lg-6  cx-img-wrapper">
                                                    <div className="cx-carousel-slide cx-first-img  d-flex flex-row mt-4 ">
                                                        <img src="https://d2x7e1xt27q73s.cloudfront.net/step44F.png" className="" alt="Wealth distribution & redistribution" />

                                                    </div>
                                                </div>
                                                <div className="col-lg-6 ">
                                                    <div className="content-text-step mt-3">
                                                        <h2>Asset Distribution
                                                            <br /> & Re-distribution</h2>
                                                        <p>Distribute your assets as you wish.<br />Re-distribution right at your fingertips.</p>
                                                        <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank"><button className='btn btn-success mb-4 '>Distribute Assets Now!</button> </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item" data-bs-interval="5000" >
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 cx-img-wrapper">
                                                    <div className="cx-carousel-slide cx-first-img  d-flex flex-row mt-4 ">
                                                        <img src="https://d2x7e1xt27q73s.cloudfront.net/step5.png" className="" alt="Auto Will Creation" />
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 '>
                                                    <div className="content-text-step mt-3">
                                                        <h2>Auto Will Creation</h2>
                                                        <p>One click Express Will creation.<br /> Auto-Will creation with one click.</p>
                                                        <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank"><button className='btn btn-success mb-4 '>Create Your Will </button> </a>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>

                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </section >



    )
}

export default HowWork2;