import React, { useEffect, useState } from 'react';

import Popper from '../Popper';
import { BASE_URL, PLAYSTORE_URL } from './urls';
import { useAlert } from 'react-alert'
import { positions } from 'react-alert'
import ReactGA, { event } from 'react-ga';
import { TRACKING_CODE } from './urls';
import LoadingBar from 'react-top-loading-bar';
import Multiselect from 'multiselect-react-dropdown';
import {
    Button, DropdownButton, MenuItem, Modal,
    OverlayTrigger, Tooltip
} from 'react-bootstrap';

import { FileUploader } from "react-drag-drop-files";


ReactGA.initialize(TRACKING_CODE);


const fileTypes = ["JPG", "PNG", "GIF"];

function Nominee() {
    const [progress, setProgress] = useState(0)
    const alert = useAlert()
    const [registerData, setRegisterData] = useState({ name: '', email: "", phone_number: "", phone_number_search: "", final_name: "" })

    useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'nominee pricing service page'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
        document.title = "nominee-service - DigiWill-Keep The Track Record of Your Assets and Liabilities"
    }, [])

    useEffect(

        () => {
            setProgress(100)
        }, []
    )
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const handleRegister = async (e) => {
        e.preventDefault()
        alert.show('Hi thank you for choosing us ! Our Representative will contact you shortly..', { position: positions.TOP_CENTER })

        const { name, email, phone_number, phone_number_search, final_name } = e.target
        const data = new FormData();

        data.append('name', name.value)
        data.append('email', email.value)
        data.append("phone_number", phone_number.value)
        data.append('phone_number_search', phone_number_search.value)
        data.append('final_name', final_name.value)
        console.log(data);
        const response = await fetch(`${BASE_URL}service_partner`, {
            method: "POST",
            body: data,
        })
        const newdata = await response.json()
        console.log(newdata);

        setRegisterData({ name: '', email: "", phone_number: "", phone_number_search: "", final_name: "", })
        setTimeout(() => {
            setProgress(25)
        }, 100);
        setTimeout(() => {
            setProgress(70)
        }, 100);
        setTimeout(() => {
            setProgress(100)
        }, 100);
        ReactGA.event({
            category: 'User',
            action: 'nominee service submitted'
        });



    }

    const handleChange = (e) => {

        if (e.target.name === 'userid') {

            setRegisterData({ ...registerData, userid: e.target.value })
        }

        if (e.target.name === 'name') {

            setRegisterData({ ...registerData, name: e.target.value })
        }

        if (e.target.name === 'phone_number') {

            setRegisterData({ ...registerData, phone_number: e.target.value })
        }

        if (e.target.name === 'final_name') {

            setRegisterData({ ...registerData, final_name: e.target.value })
        }
        if (e.target.name === 'phone_number_search') {

            setRegisterData({ ...registerData, phone_number_search: e.target.value })
        }

    }
    const [file, setFile] = useState(null);
    const handleChange1 = (file) => {
        setFile(file);
    };

    const [show, setShow] = useState(false)

    return (
        <div>
            {/* <Popper></Popper> */}
         
            <LoadingBar
                color='#f11946'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <main>

                <section id="cxp-nom">
                    <div className="cxp-nom-c d-flex align-items-center">
                        <div className="container-fluid">
                            <div className="row d-flex flex-row flex-row flex-wrap ">
                                <div className="col-lg-6  col-md-6  col-12 cxp-nom-left-wrapper">
                                    <div className="cxp-nom-content ">
                                        <img src="../images/nominee/banner.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12 ">
                                    <div className="cxp-nom-content-right">
                                        <div className="cxp-eform-nom mt-4">
                                            <h2 className='mb-4 text-center'>Nominee Center</h2>

                                            <form onSubmit={(e) => handleRegister(e)} >
                                                <h6 className='mb-4'>User Information</h6>
                                                <div className="row">
                                                    <div class="form-floating col-md-6 d-flex">
                                                        <input type="userid" class="form-control" id="userid" required value={registerData.userid} onChange={(e) => handleChange(e)} name='userid' placeholder="userid" />
                                                        <label for="fuserid">User id</label>
                                                        <p><span>{['top'].map((placement) => (
                                                            <OverlayTrigger
                                                                key={placement}
                                                                placement={placement}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-${placement}`}>
                                                                        The frequency of communication with the nominees is set by the users.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#009578" class="bi bi-info-circle ms-1 my-3" viewBox="0 0 16 16" >
                                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                </svg>
                                                            </OverlayTrigger>
                                                        ))}</span></p>

                                                    </div>
                                                    <div><a href='#' onClick={() => setShow(!show)} className='text-primary pe-auto ' >Do You Remmber user id?</a></div>

                                                </div>
                                                {show ? <div className='row mt-4 {classes.box}'  >
                                                    <div class="form-floating col-md-6">
                                                        <input type="name" class="form-control" id="name" required placeholder="Your Name" value={registerData.name} onChange={(e) => handleChange(e)} name='name' />
                                                        <label for="name">Your Name</label>
                                                    </div>
                                                    <div class="form-floating  col-md-6 ">
                                                        <input type="text" class="form-control" id="num" required placeholder="Phone No" value={registerData.phone_number} onChange={(e) => handleChange(e)} name='phone_number' />
                                                        <label for="name">Phone No</label>
                                                    </div>
                                                </div> : null}

                                                <div className='row mt-4'>
                                                    <h6 className='mt-3 mb-4'>Nominee Inforamtion</h6>
                                                    <div class="form-floating col-md-6 mb-3">
                                                        <input type="name" class="form-control" id="searchname" placeholder="Your Name" value={registerData.final_name} onChange={(e) => handleChange(e)} name='final_name' required />
                                                        <label for="name">Nominee Name</label>
                                                    </div>
                                                    <div class="form-floating col-md-6 mb-3">
                                                        <input type="name" class="form-control" id="sphone" placeholder="Phone No" value={registerData.phone_number_search} onChange={(e) => handleChange(e)} name='phone_number_search' required />
                                                        <label for="name">Phone No</label>
                                                    </div>
                                                    <div className='mt-4 text-center '>
                                                        <button type="submit" className="cx-btn">Search</button>
                                                    </div>
                                                </div>

                                                <div className='row mt-5'>
                                                    <h6 className='mb-3'>Upload Death Certificate</h6>
                                                    <div className='col-md-12'>
                                                        <FileUploader handleChange1={handleChange1} name="file" types={fileTypes} />
                                                    </div>


                                                </div>


                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </section>

            </main>
        </div>



    );

}
export default Nominee;
