import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter, Route, Routes, useLocation, Navigate } from 'react-router-dom';

import Home from './components/pages/Home';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Blog from './components/pages/Blog';
import ServicePartner from './components/pages/ServicePartner';
import BlogsDetail from './components/pages/BlogsDetail';
import Tracker from './components/pages/Tracker';
import Hotdeals from './components/pages/Hotdeals';
import Faq from './components/pages/Faq';
import Invest from './components/pages/Invest';
import Ensurance from './components/pages/Ensurance';
import ChannelPartner from './components/pages/ChannelPartner';
import Podcast from './components/pages/Podcast';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import CurosityBox from './components/pages/CurosityBox';
import ReactGA from 'react-ga';
import { TRACKING_CODE } from './components/pages/urls';
import Terms from './components/pages/Terms';
import Security from './components/pages/Security';
import Privacy from './components/pages/Privacy';
import { useLayoutEffect } from 'react'
import Claim from './components/pages/Claim';
import Nominee from './components/pages/Nominee';
import DemoVideo from './components/pages/DemoVideo'
// import Price from './components/pages/Price';
import Modal from 'react-modal';
import 'aos';
import Pricing from './components/pages/Pricing';
import ContactGet from './components/pages/ContactGet';
import DeathPage from './components/pages/DeathPage';
import Landing from './components/pages/Landing';
import Thanku from './components/pages/Thanku';
import AppSoon from './components/pages/AppSoon';
import CancleAndRefund from './components/pages/CancleAndRefund';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { AppContext } from './user/AppContext';
import AppLanding from './components/landing-page/AppLanding';
import { Windows } from 'react-bootstrap-icons';
import B2Bpage from './components/pages/B2Bpage';
import B2bHomeScreen from './components/business-to-business-dashboard/B2bHomeScreen';
import GetDemo from './components/business-to-business-dashboard/GetDemo';
import B2bLogin from './components/business-to-business-dashboard/B2bLogin';
import B2bCompanyDeatils from './components/business-to-business-dashboard/B2bCompanyDeatils';
import B2bEmpAdd from './components/business-to-business-dashboard/B2bEmpAdd';
import B2bEmpStatus from './components/business-to-business-dashboard/B2bEmpStatus';
import B2bAddSingalEmp from './components/business-to-business-dashboard/B2bAddSingalEmp';
import B2bAddMultipleEmp from './components/business-to-business-dashboard/B2bAddMultipleEmp';
ReactGA.initialize(TRACKING_CODE);

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

// const customStyles3 = {
//   content: {
//     top: '72%',
//     left: 'auto',
//     right: '30%',
//     bottom: 'auto',
//     marginRight: '-40%',
//     transform: 'translate(-50%, -50%)',
//     backgroundColor: 'white',
//     zindex: '234493940303033393939',
//     overflow: 'hidden',
//     border: '1px solid #05A081',
//     borderRadius: '20px',
//     padding: "2px",

//   },
// };

function App() {
  // const [modalIsOpen, setIsOpen] = React.useState(false);
  const [display, setDisplay] = useState("flex")

  const { show, setShow, first, setFirst } = useContext(AppContext)
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  })

  // useEffect(() => {

  //   window.onscroll = function (ev) {
  //     if (window.location.pathname == '/') {
  //       if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && first) {
  //         // you're at the bottom of the page
  //         setIsOpen(true);
  //         setDisplay("none");
  //       }
  //     }
  //   };


  // }, [first])

  const options = {
    // you can also just use 'bottom center'
    position: positions.TOP_CENTER,
    timeout: 10000,
    offset: '30px',
    // you can also just use 'scale'
    transition: transitions.SCALE
  }

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  const width = window.innerWidth
  window.addEventListener("scroll", reveal);

  useEffect(() => {
    if (width <= 972) {
      setShow('none')
    }
    else {
      setShow('flex')
    }

  }, [])

  // webchat modal html and css

  // useEffect(() => {
  //   if (!modalIsOpen) { setDisplay("flex") }


  // }, [modalIsOpen])

  // let subtitle;

  // function openModal() {
  //   setIsOpen(true);
  // }

  // function afterOpenModal() {
  //   //references are now sync'd and can be accessed.
  //   subtitle.style.color = '#f00';
  // }

  // function closeModal() {
  //   setIsOpen(false);
  // }

  // const [colOpen, setcolOpen] = React.useState(false);
  // function opencol() {
  //   setcolOpen(true);
  // }
  // function closeCol() {
  //   setcolOpen(false);
  // }


  return (
    <div >

      <AlertProvider template={AlertTemplate} {...options} >

        {window.location.pathname !== "/digiwill-app" &&
          window.location.pathname !== "/business-page-company-get-started" &&
          window.location.pathname !== "/business-page-login" &&
          window.location.pathname !== "/business-page-company-deatils" &&
          window.location.pathname !== "/business-page-employee-add" &&
          window.location.pathname !== "/business-page-employee-status" &&
          window.location.pathname !== "/business-page-single-emp-add" &&
          window.location.pathname !== "/business-page-multiple-emp-add"
          
          ? <Navbar /> : ""}


        {/* <div >
          {/* <button onClick={openModal}>Open Modal</button>    

            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={true}  //changes to true
            style={customStyles3}
            contentLabel="Example Modal"
          >
            {/* <i onClick={closeModal} className='bx bxs-x-circle float-end fs-1'></i>    
            <div className="row align-items-center ">
              <div className="col-md-12 col-12">
                <button className='minimize-button-webchat'><i onClick={() => { closeModal(); setFirst(false) }} class="bi bi-dash-lg  text-dark fs-4 p-2"></i> </button>

                <div className='webchat-wrapper'>
                  {
                    <iframe id="inlineFrameExample"
                      title="Inline Frame Example"
                      src="https://app.botosynthesis.ai/webchat/?p=1008290&ref=1661872760106&lc=0">
                    </iframe>

                  }

                </div>
              </div>
            </div>

          </Modal>
        </div> */}
        <Routes>

          <Route path='/' exact element={<Home></Home>}></Route>

          <Route path='/about' exact element={<About></About>}></Route>
          <Route path='/contact' exact element={<Contact />}></Route>
          <Route path='/blog' exact element={<Blog />}></Route>
          <Route path='/blog/:slug/' element={<BlogsDetail />}></Route>
          {/* <Route path='/blog-detail' element={<BlogsDetail />}></Route> */}
          {/* <Route path='/invest' element={<Invest />}></Route> */}
          {/* <Route path='/ensurance' element={<Ensurance />}></Route> */}
          {/* <Route path='/tracker' element={<Tracker />}></Route> */}
          {/* <Route path='/hot_deals' element={<Hotdeals />}></Route>  */}
          {/* <Route path='/curosity-box' element={<CurosityBox />}></Route> 
           <Route
              path="/curosity_box"
              element={<Navigate to="/curosity-box" replace />} />  */}
          {/* <Route path='/price' element={<Price />}></Route> */}
          <Route path='/faq' element={<Faq />}></Route>



          <Route path='/service-partner' element={<ServicePartner />}></Route>
          <Route
            path="/service_partner"
            element={<Navigate to="/service-partner" replace />} />


          <Route path='/service-partner' element={<ServicePartner />}></Route>

          <Route
            path="/service_partner"
            element={<Navigate to="/service-partner" replace />}
          />

          <Route path='/channel-partner' element={<ChannelPartner />}></Route>
          <Route
            path="/channel_partner"
            element={<Navigate to="/channel-partner" replace />} />
          <Route path='/videos' element={<Podcast />}></Route>
          <Route path='/terms-conditions' element={<Terms />}></Route>
          <Route path='/security-policy' element={<Security />}></Route>
          <Route path='/privacy-policy' element={<Privacy />}></Route>
          <Route path='/claim' element={<Claim />}></Route>

          <Route path='/nominee' element={<Nominee />}></Route>
          <Route path='/pricing' element={<Pricing />}></Route>
          <Route path='/demovideo' element={<DemoVideo />}></Route>
          <Route path='/contact-get' element={<ContactGet />}></Route>
          <Route path='/death' element={<DeathPage />}></Route>
          <Route path='/claim-assistance' element={<Landing />}></Route>
          <Route path='/thank-you' element={<Thanku />}></Route>

          <Route path='/digiwill-app' element={<AppLanding />}></Route>

          {/* b2b dashboard routes start*/}
          <Route path='/business-page' element={<B2Bpage />}></Route>
          <Route path='/business-page-company-info' element={<B2bHomeScreen />}></Route>
          <Route path='/business-page-company-get-started' element={<GetDemo />}></Route>
          <Route path='/business-page-login' element={<B2bLogin />}></Route>
          <Route path='/business-page-company-deatils' element={<B2bCompanyDeatils />}></Route>
          <Route path='/business-page-employee-add' element={<B2bEmpAdd />}></Route>
          <Route path='/business-page-employee-status' element={<B2bEmpStatus />}></Route>
          <Route path='/business-page-single-emp-add' element={<B2bAddSingalEmp />}></Route>
          <Route path='/business-page-multiple-emp-add' element={<B2bAddMultipleEmp />}></Route>

          <Route path='/cancle-and-refund-policy' element={<CancleAndRefund />}></Route>

          {/* <Route path='/app-launching-soon' element={<AppSoon />}></Route>  */}
          {/* seo Navigate task */}
          <Route
            path="/blog/page/1"
            element={<Navigate to="/blog" replace />} />
          <Route
            path="*"
            element={<Navigate to="/" replace />} />

          <Route
            path="/types-benefits-of-provident-funds-in-india"
            element={<Navigate to="/blog/the-types-and-benefits-of-different-provident-funds-in-india" replace />} />
          {/* conical tag remove then put this redirect url */}
          <Route
            path="https://www.yourDigiWill.com"
            element={<Navigate to="/" replace />} />
          <Route
            path="http://www.yourDigiWill.com"
            element={<Navigate to="/" replace />} />
          <Route
            path="http://yourDigiWill.com"
            element={<Navigate to="/" replace />} />
        </Routes>
        {window.location.pathname !== "/digiwill-app" &&
          window.location.pathname !== "/business-page-company-get-started" &&
          window.location.pathname !== "/business-page-login" &&
          window.location.pathname !== "/business-page-company-deatils" &&
          window.location.pathname !== "/business-page-employee-add" &&
          window.location.pathname !== "/business-page-employee-status" &&
          window.location.pathname !== "/business-page-single-emp-add" &&
          window.location.pathname !== "/business-page-multiple-emp-add"
          ? <Footer /> : ""}
        {/* <Footer></Footer> */}

        {/* <div id="mybutton" style={{ display: display }} className="col-12">
          <button onClick={() => { setIsOpen(!modalIsOpen); setDisplay("none") }} class="webchat-custom-btn"><i class="bi bi-whatsapp"></i></button>
        </div> */}

      </AlertProvider>




      <script>
        AOS.init();
      </script>


    </div>

  );
}

export default App;
