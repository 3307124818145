import React from 'react'

const NavbarApp = () => {
    return (
        <div>

            <nav class="navbar navbar-light ">
                <div class="container nav-set">
                    <a class="navbar-brand app-logo" href="#"><img src='https://d2x7e1xt27q73s.cloudfront.net/whitee.png'/></a>
                </div>
            </nav>



        </div>
    )
}

export default NavbarApp
