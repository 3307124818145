import React, { useState, useEffect } from 'react'

import Popper from '../Popper';
import LoadingBar from 'react-top-loading-bar'
import '../pages/Security.css'
import MetaTags from 'react-meta-tags';
function CancleAndRefund() {
    const [progress, setProgress] = useState(0)

    useEffect(

        () => {
            setProgress(100)
            document.title = "Privacy Policy - DigiWill-Keep The Track Record of Your Assets and Liabilities"
        }, []
    )
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <MetaTags>
                <title> CANCELLATION AND REFUND POLICY| DigiWill</title>
                <meta name="description" content="" />
            </MetaTags>



            {/* <Popper></Popper> */}


            <LoadingBar
                color='#f11946'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <section className="cxle-banner">
                <div className="container">
                    <div clas="row " style={{ height: "100%" }}>
                        <div className="col-12 d-flex align-items-center justify-content-center" style={{ height: "100%" }}>
                            <div className="cxle-banner-content">
                                <span>Cancellation And Refund Policy</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="cxle-terms">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="cxle-content">
                                <div class="cxle-content-in">
                                    <h4>What is your Cancellation and Refund Policy?</h4>
                                    <p>Cancellation and refund policies differ according to the payment term.</p>
                                    <p>If you purchased your subscription through a third party, please refer to any terms and conditions provided to you in connection with purchase.</p>
                                </div>
                                <div class="cxle-content-in">
                                    <h4>Monthly Subscriptions</h4>
                                    <p>When cancelling a monthly subscription, all future charges associated with future months of your subscription will be cancelled. You may notify us of your intent to cancel at any time; your cancellation will become effective at the end of your current monthly billing period. You will not receive a refund; however your subscription access and/or delivery and accompanying subscriber benefits will continue for the remainder of the current monthly billing period.</p>
                                </div>
                                <div class="cxle-content-in">
                                    <h4>Annual Subscriptions</h4>
                                    <p>When cancelling an annual subscription, all future charges associated with future years of your subscription will be cancelled. You may notify us of your intent to cancel at any time; your cancellation will become effective at the end of your current annual billing period. You will not receive a refund, prorated or otherwise, for the remainder of the annual term. However, your subscription access and/or delivery and accompanying subscriber benefits will continue for the remainder of the current annual billing period.</p>
                                </div>
                                <div class="cxle-content-in">
                                    <h4>LIFETIME SUBSCRIPTION</h4>
                                    <p>When Cancelling A Lifetime Subscription, All Future Charges Associated With Future Years Of Your Subscription Will Be Cancelled. You May Notify Us Of Your Intent To Cancel At Any Time; Your Cancellation Will Become Effective At The End Of Your Current Billing Period. You Will Not Receive A Refund, Prorated Or Otherwise, For The Remainder Of The Term. However, Your Subscription Access And/Or Delivery And Accompanying Subscriber Benefits Will Continue For The Remainder Of The Period or You Can Opt Out of it by emailing us at support@yourdigiwill.com and it will be completed in 7 days.</p>
                                </div>
                                <div class="cxle-content-in" style={{ marginBottom: "0" }}>
                                    <p style={{ marginBottom: "0" }}>In order to change or cancel your subscription please mail us support@yourdigiwill.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>





        </div>
    )
}

export default CancleAndRefund