import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { BASE_URL } from './urls';
import { FaQuoteLeft } from "react-icons/fa";
import Typewriter from 'typewriter-effect';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { simpleGetCallWithErrorResponse } from '../../api/ApiServices';
import ApiConfig from '../../api/ApiConfig';
function Testimonials() {

    const [testimonials, setTestimonials] = useState([])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    // useEffect(() => {
    //     const LoadTestimonials = async () => {
    //         const response = await fetch(`${BASE_URL}testimonials`,
    //             {
    //                 method: 'GET',
    //          }
    //         )
    //         const data = await response.json()
    //         console.log(data);
    //         setTestimonials(data)
    //     }
    //     LoadTestimonials()
    // }, [])
    useEffect(() => {
        
        simpleGetCallWithErrorResponse(ApiConfig.TESTIMONIALS).then((data) => {

            if (data.json.status) {
                setTestimonials(data.json.Testimonials_data);
            }

        })
            .catch((error) => {
                console.log("api response", error);
            });
           
    }, [])
    return (
        <div>
            <section id="cx-test" >
                <div className="container">
                    <div className="cx-outer">
                        <div className="cx-content-header">
                            <h2>Testimonials</h2>
                            {/* <p>Don't just take our word for it. Here's what our customers say about us</p> */}
                            <p className='text-center'> <Typewriter
                                options={{
                                    strings: ['Do not just take our word for it. Hear  what our customers say about us.'],
                                    autoStart: true,
                                    loop: true,
                                    pauseFor: 600000,
                                }}
                            />
                            </p>
                        </div>
                        <div className="cx-content cx-content-test">
                           <OwlCarousel className='owl-theme owl-carousel' loop margin={30} autoplay={true}  autoplayHoverPause={true} responsive={{
                                0: {
                                    items: 1,
                                  
                                },
                                600: {
                                    items: 3,
                                  
                                },
                                1000: {
                                    items: 3,
                                    loop: true
                                }
                            }} autoPlay={true} dots={false} autoplayTimeout={3000}>
                                {
                                    testimonials.map((single) => {
                                        return (
                                            <div >
                                                <figure class="snip1533 col-md-4 col-lg-12 col-12">
                                                    <figcaption>
                                                        <blockquote>
                                                            <p className='text-body'>{single.message}</p>
                                                        </blockquote>
                                                        <span className='text-body'>-{single.name}</span>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        )

                                    })
                                }
                            </OwlCarousel> 
                            
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}

export default Testimonials