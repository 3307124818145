import React, { useState, useEffect, useContext } from 'react';
import Typewriter from 'typewriter-effect';
import { BASE_URLS } from './urls';
import { AppContext } from '../../user/AppContext';

function VideoSection() {
    const width = window.innerWidth
    const { show, setShow } = useContext(AppContext)
    useEffect(() => {

        if (width <= 972) {
            setShow('none')
        }
        else {
            setShow('flex')
        }

    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        // this is features video for home screen section start 
        <section id="cx-banner-price" className=' '>
            <div className="container">
                <div className="cx-banner-outer">
                    <div className="cx-content-header mb-4">
                        <h2 className='mb-2 align-center cx-features-heading text-dark'>Aapki Virasat Aapke Apno Ke liye</h2>

                    </div>
                    <div className='video-section-wrapper'>
                        <div className="col-lg-10 col-sm-12 col-md-12 mx-auto my-auto ">
                            <div className="cx-video-section-wrapper w-responsive ">
                                {/* <iframe width="100%" height="750px" src="https://www.youtube.com/embed/dlqPkQjfx7U" title="A Demonstration of the DigiWill App" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                                 <video width='100%'
                                    height='100%'
                                    src="https://d2x7e1xt27q73s.cloudfront.net/pr+vid.mp4"
                                    muted
                                    autoPlay={"autoplay"}
                                    preload="auto"
                                    loop
                                    webkit-playsinline="true" 
                                    playsinline="true"
                                    disablePictureInPicture
                                    aspectRatio={1}
                                    title='Online Virasat App'                       
                                ></video> 
                                
                                {/* <iframe src="https://websiteimagesDigiWill.s3.ap-south-1.amazonaws.com/test9+(2)+2.mp4" autoplay="true" allowtransparency="true" frameborder="0" allowfullscreen="" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true" msallowfullscreen="true"></iframe> */}
                            </div>
                            <div className='cta-video-wrapper d-flex flex-row justify-content-center'>
                            <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank"><button className='btn btn-primary'>Create Your Virasat</button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>





    )
}

export default VideoSection;