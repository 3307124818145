import React, { useEffect, useState, useContext } from 'react';
import Popper from '../Popper';
import { BASE_URLS, PLAYSTORE_URL, TRACKING_CODE } from './urls';
import { useAlert } from 'react-alert'
import { positions } from 'react-alert'
import LoadingBar from 'react-top-loading-bar'
import FooterEnsurance from './FooterEnsurance';
import ReactGA from 'react-ga';
import MetaTags from 'react-meta-tags';
import { AppContext } from '../../user/AppContext';
import { PostCallWithErrorResponse } from '../../api/ApiServices';
import ApiConfig from '../../api/ApiConfig';
import ReCAPTCHA from "react-google-recaptcha";
ReactGA.initialize(TRACKING_CODE);
function B2Bpage() {
    const width = window.innerWidth
    const { show, setShow } = useContext(AppContext);
    const [progress, setProgress] = useState(0)
    const alert = useAlert()
    const [registerData, setRegisterData] = useState({ name: "", email: "", mobile_no: "", team_size: "", company_name: "" })
    //const [success,setsuccess]=useState(false)
    const [verifed, setVerifed] = useState(false);
    function onChange(value) {
        console.log("Captcha value:", value);
        setVerifed(true);
    }
    useEffect(() => {

        if (width <= 972) {
            setShow('none')
        }
        else {
            setShow('flex')
        }

    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'visited b2b page'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])


    useEffect(

        () => {
            setProgress(100)
            document.title = "b2b page"
        }, []
    )
    const handleRegister = async (e) => {
        e.preventDefault()
        alert.show('Hi thank you for choosing us ! Our Representative will contact you shortly..', { position: positions.TOP_CENTER })

        PostCallWithErrorResponse(ApiConfig.B2BPAGE, registerData).then((data) => {
            console.log(data.json)
            if (data.json.status) {
                // console.log("first")
                setRegisterData({ name: "", email: "", mobile_no: "", team_size: "", company_name: "" })
                setTimeout(() => {
                    setProgress(25)
                }, 100);
                setTimeout(() => {
                    setProgress(70)
                }, 100);
                setTimeout(() => {
                    setProgress(100)
                }, 100);
            }
        })
            .catch((error) => {
                console.log("api response", error);
            });
        ReactGA.event({
            category: 'User',
            action: 'b2b contact form'
        });


    }

    const handleChange = (e) => {

        if (e.target.name === 'name') {

            setRegisterData({ ...registerData, name: e.target.value })
        }

        if (e.target.name === 'email') {

            setRegisterData({ ...registerData, email: e.target.value })
        }

        if (e.target.name === 'mobile_no') {

            setRegisterData({ ...registerData, mobile_no: e.target.value })
        }

        if (e.target.name === 'company_name') {

            setRegisterData({ ...registerData, company_name: e.target.value })
        }
        if (e.target.name === 'team_size') {

            setRegisterData({ ...registerData, team_size: e.target.value })
        }

    }

    return (<div>
        <MetaTags>
            <title>Secure Your Team's Financial Future with DigiWill</title>
            <meta name="description" content="Financial inheritance can help your employees ensure that their loved ones are financially protected in the event of their death. Get in touch with us now!" />
        </MetaTags>


        {/* <Popper></Popper> */}

        <LoadingBar
            color='#f11946'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
        />
        <section id="cxc-hero">
            <div className="cxc-hero-b2b d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="cxc-hero-content">
                                <h2 className='fs-1 mb-4'>Secure Your Team's Financial & Non - Financial Inheritance<br /> Future with DigiWill</h2>
                                <p>Help your employees plan for the transfer of their assets to their heir</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="cxc-entails">
            <div className="cxc-outer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                           
                            <div className="cxc-entails-content-b2b">

                                <ul className="cxc-elist">
                                    <li>
                                        <h3>How Financial Inheritance can help your employees?</h3>
                                        Financial inheritance can help your employees ensure that their loved ones are financially protected in the event of their death, which can provide peace of mind and financial security.
                                    </li>
                                    <li>
                                        <h3>Why Choose DigiWill?</h3>
                                        We have one mission, to ensure every family gets assets that are rightfully theirs, and nothing remains unclaimed.
                                    </li>
                                    <li>

                                        India’s only DIGITAL VIRASAT solution. Enabling users to
                                        create tokenized + encrypted auto-discovered financial &
                                        non-financial asset records, nominate their loved ones across all
                                        assets, investments & savings through one single platform,
                                        setup real-time or future event-based communications of Wills & Records,
                                        declare asset distribution & update distribution of assets with just
                                        a few clicks & create an express will with 1 click or
                                        enable auto will creation. Additionally, we serve your
                                        dependents and widows with a doorstep handheld all-asset
                                        claim service delivered through a dedicated service manager, with a choice of
                                        women service managers for kids and women in the family.
                                    </li>
                                    <li>

                                        DigiWill aims to become a household name for millions of
                                        families for inheritance and make the world a place where
                                        no family has to struggle for what
                                        is rightfully theirs and no assets remain unclaimed.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="cxc-entails-content">
                                <div className="cxc-eform">
                                    <h2>Business to Business Connect</h2>
                                    <p>Fill in the below form and we’ll reach out to you</p>
                                    <form onSubmit={(e) => handleRegister(e)} >
                                        <div className="mb-3">
                                            <input type="text" pattern="^[A-Za-z -]+$" maxlength="38" size="40" required value={registerData.name} onChange={(e) => handleChange(e)} name='name' className="form-control" id="exampleInputEmail1" placeholder="Full Name" />
                                        </div>
                                        <div className="mb-3">
                                            <input type="email" size="30" required value={registerData.email} onChange={(e) => handleChange(e)} name='email' className="form-control" id="exampleInputEmail1" placeholder="Work Email" />
                                        </div>
                                        <div className="mb-3">
                                            <input type="text" pattern="^[A-Za-z -]+$" maxlength="38" size="40" required value={registerData.company_name} onChange={(e) => handleChange(e)} name='company_name' className="form-control" id="exampleInputEmail1" placeholder="Company Name" />
                                        </div>
                                        <div className="mb-3">
                                            <input type="tel" pattern="[0-9]{10}" required value={registerData.mobile_no} onChange={(e) => handleChange(e)} name='mobile_no' className="form-control" id="exampleInputEmail1" placeholder="Phone No" />
                                        </div>
                                        <div className="mb-3">
                                            <input name='team_size' required value={registerData.team_size} onChange={(e) => handleChange(e)} className=" form-control" placeholder="Team Size" aria-label="Default select example" />
                                        </div>

                                        <div className='mb-3 d-flex flex-row justify-content-center'>
                                            <div className="captcha" style={{ transform: "scale(0.80)" }}>
                                                <ReCAPTCHA
                                                    sitekey="6LfPk6MjAAAAAILoHwcOKf-55xEIYFxc9tyWQzsw"
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                        <button type="submit" className="cx-btn" disabled={!verifed}>Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="cxp-download">
            <div className="container">
                <div className="cxp-outer">
                    <div className="cxp-download-content">
                        <div className="cxp-download-btn">
                            {/* <a href="#" style={{marginRight:"8rem"}}><img src="./images/home/google_play.png" alt='optional' /></a> */}
                            {/*      <a href="#"><img src="./images/home/google_play.png" /></a> */}
                        </div>
                        <h4>Download India's Only One-Stop Financial & Non-Financial Asset Inheritance Solution.</h4>
                        <div >
                            <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" className="cx-banner-btns"><img src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/google-play-badge.png" style={{ height: "65.79px", width: "170px" }} alt="DigiWill App - Get it on Google Play Store" /></a>
                            {/*    <a href="#"><img src="images/home/app_store.png" /></a> */}


                        </div>
                    </div>
                </div>
            </div>
        </section>

        <FooterEnsurance />






    </div>);
}

export default B2Bpage;
