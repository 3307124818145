import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Redirect } from 'react-router';
import Popper from '../Popper';
import { BASE_URLS, PLAYSTORE_URL, TRACKING_CODE } from './urls';
import { useAlert } from 'react-alert'
import { positions } from 'react-alert'
import LoadingBar from 'react-top-loading-bar'

import ReactGA from 'react-ga';
import MetaTags from 'react-meta-tags';
import { AppContext } from '../../user/AppContext';
import ReCAPTCHA from "react-google-recaptcha";
import { PostCallWithErrorResponse } from '../../api/ApiServices';
import ApiConfig from '../../api/ApiConfig';
ReactGA.initialize(TRACKING_CODE);
function Landing() {
    let navigate = useNavigate();
    const width = window.innerWidth
    const { show, setShow } = useContext(AppContext);
    const [progress, setProgress] = useState(0)
    const alert = useAlert()
    const [registerData, setRegisterData] = useState({ name: '', email: "", phone_no: "", city: "", purpose: "" })
    //const [success,setsuccess]=useState(false)
    const [verifed, setVerifed] = useState(false);

    function onChange(value) {
        console.log("Captcha value:", value);
        setVerifed(true);
    }
    useEffect(() => {

        if (width <= 972) {
            setShow('none')
        }
        else {
            setShow('flex')
        }

    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        ReactGA.event({
            category: 'claim-assistance',
            action: 'Thank you for filling out the details. Our team will get back to you shortly.e'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])


    useEffect(

        () => {
            setProgress(100)
            document.title = "claim-assistance - DigiWill-Keep The Track Record of Your Assets and Liabilities"
        }, []
    )
    // const handleRegister = async (e) => {
    //     e.preventDefault()
    //     window.location.reload();
    //     alert.show('Thank you for filling out the details. Our team will get back to you shortly.', { position: positions.TOP_CENTER })

    //     const { name, email, phone_no, city, purpose } = e.target
    //     const data = new FormData();

    //     data.append('name', name.value)
    //     data.append('email', email.value)
    //     data.append("phone_no", phone_no.value)
    //     data.append('city', city.value)
    //     data.append('purpose', purpose.value)
    //     console.log(data);
    //     const response = await fetch(`${BASE_URLS}claim-assistance`, {
    //         method: "POST",
    //         body: data,
    //     })
    //         .then(() => {
    //             navigate("/thank-you", { replace: true });
    //         })
    //     const newdata = await response.json()
    //     //console.log(newdata);
    //     //setsuccess(true)
    //     setRegisterData({ name: "", email: "", phone_no: "", city: "", purpose: "" })
    //     setTimeout(() => {
    //         setProgress(25)
    //     }, 100);
    //     setTimeout(() => {
    //         setProgress(70)
    //     }, 100);
    //     setTimeout(() => {
    //         setProgress(100)
    //     }, 100);

    // }
    const handleRegister = async (e) => {
        e.preventDefault()
        alert.show('Hi thank you for choosing us ! Our Representative will contact you shortly..', { position: positions.TOP_CENTER })

        PostCallWithErrorResponse(ApiConfig.CLAIMASSISTANCELANDINGAPGE, registerData).then((data, then) => {
            console.log(data.json)

            if (data.json.status) {

                console.log("first")
                setRegisterData({ name: "", email: "", phone_no: "", city: "", purpose: "" })
                setTimeout(() => {
                    setProgress(25)
                }, 100);
                setTimeout(() => {
                    setProgress(70)
                }, 100);
                setTimeout(() => {
                    setProgress(100)
                }, 100);
            }
        })
            .then(() => {
                navigate("/thank-you", { replace: true });
            })
            .catch((error) => {
                console.log("api response", error);
            });
        ReactGA.event({
            category: 'User',
            action: 'Service partner contact form'
        });



    }

    const handleChange = (e) => {

        if (e.target.name === 'name') {

            setRegisterData({ ...registerData, name: e.target.value })
        }

        if (e.target.name === 'email') {

            setRegisterData({ ...registerData, email: e.target.value })
        }

        if (e.target.name === 'phone_no') {

            setRegisterData({ ...registerData, phone_no: e.target.value })
        }

        if (e.target.name === 'city') {

            setRegisterData({ ...registerData, city: e.target.value })
        }
        if (e.target.name === 'purpose') {

            setRegisterData({ ...registerData, purpose: e.target.value })
        }

    }

    // faq section js write here start
    const ref = useRef(null);
    useEffect(() => {
        const btns = document.querySelectorAll(".acc-btn");
        function accordion() {
            // this = the btn | icon & bg changed
            this.classList.toggle("is-open");

            // the acc-content
            const content = this.nextElementSibling;

            // IF open, close | else open
            if (content.style.maxHeight) content.style.maxHeight = null;
            else content.style.maxHeight = content.scrollHeight + "px";
        }

        // event
        btns.forEach((el) => el.addEventListener("click", accordion));
    }, []);
    // faq section js write here end
    // modal form open and close section js start
    useEffect(() => {
        var modals = document.getElementById("myModal");

        // Get the button that opens the modal
        var btn = document.getElementById("myBtn");
        var btn1 = document.getElementById("myBtn1");
        var btn2 = document.getElementById("myBtn2");
        var btn3 = document.getElementById("myBtn3");

        // Get the <span> element that closes the modal
        var span = document.getElementsByClassName("close")[0];

        // When the user clicks the button, open the modal
        btn.onclick = function () {
            modals.style.display = "block";
        }
        btn1.onclick = function () {
            modals.style.display = "block";
        }
        btn2.onclick = function () {
            modals.style.display = "block"
        }
        btn3.onclick = function () {
            modals.style.display = "block"
        }
        // When the user clicks on <span> (x), close the modal
        span.onclick = function () {
            modals.style.display = "none";
        }

        // When the user clicks anywhere outside of the modal, close it
        window.onclick = function (event) {
            if (event.target == modals) {
                modals.style.display = "none";
            }
        }

    }, [])

    // modal form open and close section js end

    return (

        <div>
            <MetaTags>
                <title>Hassle-Free Claim Assistance Service for Every Asset of Your Loved Ones - DigiWill</title>
                <meta name="description" content=" Claiming an asset of your loved one can be a tricky process, but we can help you along the way. We are here to make your claim smooth and hassle-free. Get in touch with us today!" />
            </MetaTags>


            {/* <Popper></Popper> */}

            <LoadingBar
                color='#f11946'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <section class="masthead" id="masthead1">
                <div class="container px-5">
                    <div class="row gx-5 align-items-center text-center">
                        <div class="masthead-heading">
                            <h2 >Hassle Free Claim Service For The
                                Assets Of Your Loved Ones.</h2>
                        </div>
                        <div class="col-lg-6" >
                            {/* Masthead device left contain */}
                            <div class="masthead-device-mockup ">
                                <img src="./images/landing/banner.png"
                                    class="animate__animated animate__pulse animate__infinite	infinite " />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            {/* Mashead form */}
                            <div class="mb-5 mb-lg-0 text-center text-lg-start " >

                                <form class="row g-3 form-register" onSubmit={(e) => handleRegister(e)} name="myForm">
                                    <div class="form-floating  col-12">
                                        <input type="text" class="form-control" value={registerData.name} onChange={(e) => handleChange(e)} id="floatingInput" placeholder="First Name"
                                            name="name" required />
                                        <label >Full Name</label>
                                    </div>
                                    <div class="form-floating col-12">
                                        <input type="email" class="form-control" value={registerData.email} onChange={(e) => handleChange(e)} id="floatingInput"
                                            placeholder="name@example.com" name="email" required />
                                        <label>Email address</label>
                                    </div>
                                    <div class="form-floating  col-md-6">
                                        <input type="tel" pattern="[0-9]{10}" class="form-control" value={registerData.phone_no} onChange={(e) => handleChange(e)} name="phone_no" id="floatingInput" placeholder="Phone Number"
                                            required />
                                        <label >Phone Number</label>
                                    </div>
                                    <div class="form-floating col-md-6">
                                        <input type="text" class="form-control" value={registerData.city} onChange={(e) => handleChange(e)} id="floatingInput" placeholder="City"
                                            name="city" required />
                                        <label >City</label>
                                    </div>
                                    <div class="form-floating col-12">
                                        <textarea class="form-control" value={registerData.purpose} onChange={(e) => handleChange(e)} name='purpose' placeholder="Leave a comment here" id="floatingTextarea2"
                                            style={{ height: "100px" }} required></textarea>
                                        <label >Purpose</label>
                                    </div>
                                    <div class="form-floating col-12">
                                        <div className="captcha" style={{ transform: "scale(0.80)", transformOrigin: "0 0" }}>
                                            <ReCAPTCHA
                                                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        {/* <div class="g-recaptcha" data-sitekey="6Lel4Z4UAAAAAOa8LO1Q9mqKRUiMYl_00o5mXJrR"></div> */}
                                        <button type="submit" class="btn-hover color-5" disabled={!verifed}>Submit</button>
                                        {/* <Link className="dropdown-item" to="/thank-you"><button type="submit" class="btn-hover color-5">Submit</button></Link> */}
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Mashead section1 end */}
            {/* <!-- section 2 what is claim section start--> */}
            <section id="features">
                <div class="container px-5">
                    <div class="row gx-5 ">
                        <div class="col-lg-12  features-wrapper">
                            <h1 class="mb-4">What is Claim Assistance? </h1>
                            <p>Claims assistance is a service provided by
                                professionals who are responsible for mailing
                                claims checks, and filing and processing claims into the company's system.
                                In easy words, we stand strong and fight
                                for you and your loved ones to
                                ensure that your loved ones claim every asset of yours.
                            </p>
                            <a href="#" ><button class="btn-hover color-5" id="myBtn3" onclick="openForm()" >Get a Quote</button></a>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- section 2 what is claim section end--> */}
            {/* <!-- section 3 why do you need claim section start--> */}
            <section id="why">
                <div class="container">
                    <div class="row why-features-wrapper">
                        <div class="col-12 mb-5">
                            <h1 class=" text-center">Why do you need a Claim Assistance?</h1>
                        </div>
                        <div class="col-md-6 col-lg-4 col-12 ">
                            {/* <!-- Bootstrap 5 card box --> */}
                            <div class="card-box">
                                <div class="card-thumbnail">
                                    <img src="./images/landing/why/smplify.svg" class="img-fluid" alt="" />
                                </div>
                                <h3 class="mt-3"><a href="#" class="mt-4">Simplifying claim procedures</a></h3>
                                <p class="">Claim Assistance helps you with the claim procedure because filing a claim can be a
                                    long & complicated process.
                                </p>

                            </div>
                        </div>

                        <div class="col-md-6 col-lg-4 col-12">
                            {/* <!-- Bootstrap 5 card box --> */}
                            <div class="card-box">
                                <div class="card-thumbnail">
                                    <img src="./images/landing/why/claim.svg" class="img-fluid" alt="" />
                                </div>
                                <h3 class="mt-3"><a href="#" class="mt-4 ">Claim Assistance</a></h3>
                                <p class="">Our professionals take the stress off your mind and smoothen the process.
                                </p>

                            </div>
                        </div>

                        <div class="col-md-6 col-lg-4 col-12">
                            {/* <!-- Bootstrap 5 card box --> */}
                            <div class="card-box">
                                <div class="card-thumbnail">
                                    <img src="./images/landing/why/recv.svg" class="img-fluid" alt="" />
                                </div>
                                <h3 class="mt-3"><a href="#" class="mt-4 ">Maximum Recovery</a></h3>
                                <p class="">Getting claim preparation advice or opting for all-out help with the entire process
                                    through final negotiations can be a wise and comforting move.
                                </p>

                            </div>
                        </div>


                    </div>
                </div>
            </section>
            {/* <!-- section 3 why do you need claim section end--> */}
            {/* <!-- section 4  Our Claim Assistance Process section start--> */}
            <section id="our-claim">
                <div class="container">
                    <div class="row our-features-wrapper">
                        <div class="col-12 mb-5">
                            <h1 class=" text-center" > Our Claim Assistance Process</h1>
                        </div>
                        <div class="col-12 ">
                            <ul class="progressbar1">
                                <li><img src="./images/landing/our/Account.png" alt="" /></li>
                                <li><img src="./images/landing/our/Documentation.png" alt="" /></li>
                                <li><img src="./images/landing/our/Claim Processing.png" alt="" /></li>
                                <li><img src="./images/landing/our/Claim Settlement.png" alt="" /> </li>
                            </ul>
                        </div>
                        <div class="col-12">
                            <ul class="progressbar">
                                <li class="active fs-7">Register </li>
                                <li class="active fs-7">Documentation </li>
                                <li class="active fs-7"> Claim Processing? </li>
                                <li class="active fs-7">Claim Settlement </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </section>
            {/* <!-- section 4  Our Claim Assistance Process section end-> */}
            {/* <!--first Call to action section start--> */}
            <section class="cta ">
                <div class="cta-content">
                    <div
                        class="container cta-content-wrapper d-flex flex-row flex-wrap text-center justify-content-between px-5">
                        <h2 class="">
                            Get Smooth & Hassle-Free Claim Assistance Service
                        </h2>
                        <a class="btn btn-outline-light btn-lg bg-light text-dark mx-auto " id="myBtn" href="#">Get a
                            Quote</a>
                        <div id="myModal" class="modals">
                            <div class="modal-contents">
                                <div class="">
                                    <div class="d-flex flex-row justify-content-between align-items-center text-center mb-3">
                                        <h3 class="text-center">Fill this form & we will get in touch with you.</h3>
                                        <span class="close">&times;</span>

                                    </div>

                                    <form class="row g-3 form-register" onSubmit={(e) => handleRegister(e)}>
                                        <div class="form-floating  col-12 col-lg-6">
                                            <input value={registerData.name} onChange={(e) => handleChange(e)} name="name" type="text" class="form-control" id="floatingInput" placeholder="First Name" />
                                            <label for="floatingName">Full Name</label>
                                        </div>
                                        <div class="form-floating col-12 col-lg-6">
                                            <input value={registerData.email} onChange={(e) => handleChange(e)} name="email" type="email" class="form-control" id="floatingInput"
                                                placeholder="name@example.com" />
                                            <label for="floatingInput">Email address</label>
                                        </div>
                                        <div class="form-floating  col-12 col-lg-6">
                                            <input pattern="[0-9]{10}" value={registerData.phone_no} onChange={(e) => handleChange(e)} name="phone_no" type="tel" class="form-control" id="floatingInput"
                                                placeholder="Phone Number" />
                                            <label for="floatingNumber">Phone Number</label>
                                        </div>
                                        <div class="form-floating  col-12 col-lg-6">
                                            <input value={registerData.city} onChange={(e) => handleChange(e)} name="city" type="text" class="form-control" id="floatingInput" placeholder="City" />
                                            <label for="floatingLname">City</label>
                                        </div>
                                        <div class="form-floating col-12 col-lg-6">
                                            <textarea value={registerData.purpose} onChange={(e) => handleChange(e)} name="purpose" class="form-control" placeholder="Leave a comment here"
                                                id="floatingTextarea2"></textarea>
                                            <label for="floatingTextarea2">Purpose</label>
                                        </div>
                                        <div className="form-floating col-12 col-lg-6" >
                                            <div className="captcha" style={{ transform: "scale(0.80)", transformOrigin: "0 0" }}>
                                                <ReCAPTCHA
                                                    sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-12">
                                            <Link className="dropdown-item" to="/thank-you"><button type="submit" disabled={!verifed} class="btn-hover color-5">Submit</button></Link>
                                        </div>
                                    </form>
                                </div>


                            </div>

                        </div>


                    </div>
                </div>
            </section>
            {/* <!--first Call to action section end--> */}
            {/* <!-- section 6 about DigiWill section start--> */}
            <section id="about">
                <div class="container">
                    <div class="row about-wrapper">
                        <div class="col-12 mb-4">
                            <h1 class=" text-center" >About DigiWill </h1>
                        </div>
                        <div class=" about-wrapper-content">
                            <div class="col-md-6 col-lg-6 gx-3 about-wrapper-card" >
                                <p>DigiWill is a One Stop
                                    Financial & Non-Financial Asset
                                    Inheritance Solution to store & maintain records of all assets &
                                    liabilities in one place, with encryption data transfer, and secure storage to hassle-free
                                    communicating & transferring these records to the assigned nominees just with the ease of
                                    your phone backed with advanced technology & long-term support.
                                </p>
                            </div>
                            <div class="col-md-6 col-lg-6  about-wrapper-card" >
                                <p>DigiWill is that family member that shows up when everyone else turns their backs, it keeps
                                    the family updated with the earning members and families' investments, assets, savings, and
                                    liabilities, and hand holds the widowed, dependents, and kids to get what's rightfully
                                    theirs.
                                </p>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
            {/* <!-- section 6 about DigiWill section end--> */}
            {/* <!-- section 7 service section start--> */}
            <section id="services">
                <div class="container">
                    <div class="row service-features-wrapper">
                        <div class="col-12 mb-4">
                            <h1 class=" text-center">Our Services</h1>
                        </div>
                        <div class="col-md-6 col-lg-4 col-12">
                            {/* <!-- Bootstrap 5 card box --> */}
                            <div class="card-box">
                                <div class="card-thumbnail">
                                    <img src="./images/landing/service/family.jpg" class="img-fluid" alt="" />
                                </div>
                                <h3 class="mt-3"><a href="#" class="mt-4">Family Communication</a></h3>
                                <p class="">Real-Time Asset Tracking System and Event based Communication System </p>

                            </div>
                        </div>

                        <div class="col-md-6 col-lg-4 col-12">
                            {/* <!-- Bootstrap 5 card box --> */}
                            <div class="card-box">
                                <div class="card-thumbnail">
                                    <img src="./images/landing/service/ensurance.jpg" class="img-fluid" alt="" />
                                </div>
                                <h3 class="mt-3"><a href="#" class="mt-4 ">Ensurance Plan</a></h3>
                                <p class="">
                                    Hassle-Free Doorstep Claim Assistance,a support system for the most unforeseen events of
                                    life </p>

                            </div>
                        </div>

                        <div class="col-md-6 col-lg-4 col-12">
                            {/* <!-- Bootstrap 5 card box --> */}
                            <div class="card-box">
                                <div class="card-thumbnail">
                                    <img src="./images/landing/service/lifetime.jpg" class="img-fluid" alt="" />
                                </div>
                                <h3 class="mt-3"><a href="#" class="mt-4 ">Lifetime Assurance</a></h3>
                                <p class="">
                                    Life Time Subscription to all our premium features at one time cost.
                                </p>

                            </div>
                        </div>


                    </div>
                </div>
            </section>
            {/* <!-- section 7 service section end--> */}
            {/* <!--second Call to action section--> */}
            <section class="cta ">
                <div class="cta-content">
                    <div class="container cta-content-wrapper d-flex flex-row flex-wrap text-center justify-content-between ">

                        <h2 class="mx-auto my-auto">
                            We Help You Claim Your Inheritance.
                        </h2>


                        <a class="btn btn-outline-light btn-lg bg-light text-dark mx-auto" id="myBtn1" onclick="openForm()"
                            href="#">Get a
                            Quote</a>


                    </div>
                </div>
            </section>

            {/* <!-- section 9 DigiWill features section start--> */}
            <section id="DigiWill-features">
                <div class="container">
                    <div class="row DigiWill-features-wrapper ">
                        <div class="col-12 mb-4 ">
                            <h1 class=" text-center" >Our Features</h1>
                        </div>
                        <div class="d-flex flex-wrap flex-row ">
                            <div class="col-lg-4 col-md-4 col-12 DigiWill-features-border ">
                                <div class="DigiWill-features-card card ">
                                    <img src="./images/landing/feature/data protect.png" alt="not loded" />
                                    <h4>Data protection </h4>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12 DigiWill-features-border">
                                <div class="DigiWill-features-card card">
                                    <img src="./images/landing/feature/sequirty.png" alt="not loded" />
                                    <h4>Independent Security Testing</h4>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12 DigiWill-features-border2">
                                <div class="DigiWill-features-card card">
                                    <img src="./images/landing/feature/247.png" alt="not loded" />
                                    <h4>24/7 Monitoring</h4>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12 DigiWill-features-border1">
                                <div class="DigiWill-features-card card">
                                    <img src="./images/landing/feature/sequre.png" alt="not loded" />
                                    <h4>Secure Login</h4>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12 DigiWill-features-border1">
                                <div class="DigiWill-features-card card">
                                    <img src="./images/landing/feature/doorstep.png" alt="not loded" />
                                    <h4>Hassle free Doorstep assistance</h4>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4  col-12">
                                <div class="DigiWill-features-card card">
                                    <img src="./images/landing/feature/technical.png" alt="not loded" />
                                    <h4>Backed by cutting-edge technology</h4>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </section>
            {/* <!-- section 9 DigiWill features section end--> */}
            {/* <!-- section 11 Client Testimonials start --> */}
            <section id="Testimonials">
                <div class="container">
                    <div class="row Testimonials-wrapper ">
                        <div class="col-12 mb-4">
                            <h1 class=" text-center" > Client Testimonials</h1>
                        </div>
                        <div class=" Testimonials-wrapper-card d-flex flex-row flex-wrap  ">
                            <figure class="snip153334 col-md-4 col-lg-12 col-12">
                                <figcaption>
                                    <blockquote>
                                        <p>If you do the job badly enough, sometimes you don't get asked to do it again.</p>
                                    </blockquote>
                                    <h3>Manoj</h3>

                                </figcaption>
                            </figure>
                            <figure class="snip153334 col-md-4 col-lg-12 col-12">
                                <figcaption>
                                    <blockquote>
                                        <p>I'm killing time while I wait for life to shower me with meaning and happiness.</p>
                                    </blockquote>
                                    <h3>Jai</h3>

                                </figcaption>
                            </figure>
                            <figure class="snip153334 col-md-4 col-lg-12 col-12">
                                <figcaption>
                                    <blockquote>
                                        <p>The only skills I have the patience to learn are those that have no real application
                                            in life. </p>
                                    </blockquote>
                                    <h3>Rakhi</h3>

                                </figcaption>
                            </figure>

                        </div>


                    </div>
                </div>
            </section>
            {/* <!-- section 11 Client Testimonials end --> */}

            {/* <!--third Call to action section 12--> */}
            <section class="cta ">
                <div class="cta-content">
                    <div
                        class="container cta-content-wrapper d-flex flex-row flex-wrap text-center justify-content-between px-5">
                        <h2 class="">
                            Don't miss out on what's rightfully yours.
                        </h2>
                        <a class="btn btn-outline-light btn-lg bg-light text-dark mx-auto" id="myBtn2" onclick="openForm()"
                            href="#">Get a
                            Quote</a>
                    </div>
                </div>
            </section>

            {/* <!-- section 13 FAQ section  --> */}
            <section id="faq">
                <div class="container">
                    <div class="faq-wrapper ">
                        <div class="col-12  mb-4">
                            <h1 class=" text-center" > FAQs</h1>
                        </div>
                        <div class="d-flex flex-row flex-wrap">
                            <div class="col-md-6 col-lg-6 col-12 text-center mx-auto my-auto">
                                <div>
                                    <img src="./images/landing/faq/5219071.png" />
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6 col-12 text-center ">
                                <main class="card1">

                                    <div class="acc-container" ref={ref}>

                                        <button class="acc-btn" >How do I get help with a claim?</button>
                                        <div class="acc-content">
                                            <p>
                                                Fill out a form, or experts reach out, we understand what your problem is, and
                                                then we connect you to a suitable person.
                                            </p>
                                        </div>

                                        <button class="acc-btn">
                                            What information will I need when lodging a claim?
                                        </button>
                                        <div class="acc-content">
                                            <p>
                                                For the initial process you don't need any hassle of serious documentation, just
                                                fill in the form and our claim ass manager will reach out to you and will take
                                                care of the rest.
                                            </p>
                                        </div>

                                        <button class="acc-btn">
                                            How long will the whole process take?
                                        </button>
                                        <div class="acc-content">
                                            <p>
                                                The time period depends on your claim but we make sure to get it done as soon as
                                                possible for you whilst also giving you live updates from our end.
                                            </p>
                                        </div>

                                        <button class="acc-btn">
                                            Shall I be in person or is it an online process?
                                        </button>
                                        <div class="acc-content">
                                            <p>
                                                YOU get to decide this, we can take care of the entire process online and you
                                                can also rely on our team to reach you out personally.
                                            </p>
                                        </div>

                                        <button class="acc-btn">
                                            When will I get the claim that I requested?
                                        </button>
                                        <div class="acc-content">
                                            <p>
                                                After filling out the form our claim assistance calls you within 24 hours.
                                            </p>
                                        </div>

                                        <button class="acc-btn">
                                            What are the charges for the service?
                                        </button>
                                        <div class="acc-content">
                                            <p>
                                                Our first consultation is free of cost.
                                            </p>
                                        </div>

                                        <button class="acc-btn">
                                            How to get in touch with Digi Will for any queries?
                                        </button>
                                        <div class="acc-content">
                                            <p>
                                                We will be more than happy to resolve any of your queries, You can call us at
                                                +917559478151 or email us at info@yourdigiwill.com.
                                            </p>
                                        </div>

                                    </div>
                                </main>

                            </div>
                        </div>


                    </div>
                </div>
            </section>
            {/* youtube video section start */}
            <section id="video">
                <div class="container">
                    <div class="row">
                        <div class="col-12 mb-4">
                            <h1 class="text-center mb-2" >Our Videos</h1>
                        </div>
                        <div class="d-flex flex-wrap flex-row video-wrapper col-12 text-center align-items-center">
                            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-inner" role="listbox">

                                        {/* <!--First slide--> */}
                                        <div class="carousel-item active">

                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class=" mb-2">

                                                        <div class="">
                                                            <iframe width="350" height="200"
                                                                src="https://www.youtube.com/embed/0EYT7k7u5jU"
                                                                title="Why Choose DigiWill?" frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowfullscreen></iframe>
                                                            <p>Why Choose DigiWill? </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4 clearfix d-none d-md-block">
                                                    <div class=" mb-2">

                                                        <div class="">
                                                            <iframe width="350" height="200"
                                                                src="https://www.youtube.com/embed/G9kaso_ijmI"
                                                                title="Solution to keeps your information beyond safe - DigiWill App"
                                                                frameborder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowfullscreen></iframe>
                                                            <p>Solution to keeps your information beyond safe-DigiWill App </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4 clearfix d-none d-md-block">
                                                    <div class=" mb-2">

                                                        <div class="">
                                                            <iframe width="350" height="200"
                                                                src="https://www.youtube.com/embed/XYtbPffrFUs"
                                                                title="#DigiWill Allows You To Easily Keep A Record Of Your Assets And Liabilities"
                                                                frameborder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowfullscreen></iframe>
                                                            <p>DigiWill allows you to easily keep a record of your assets and
                                                                liabilities </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {/* <!--/.First slide-->

                                <!--Second slide--> */}
                                        <div class="carousel-item">

                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class=" mb-2">

                                                        <div class="">
                                                            <iframe width="350" height="200"
                                                                src="https://www.youtube.com/embed/zYA4zjK9rlQ"
                                                                title="A Safe and Secure Data Storage App for Your Assets and Liabilities"
                                                                frameborder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowfullscreen></iframe>
                                                            <p>A safe and secure data storage app for your assets and
                                                                liabilities </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4 clearfix d-none d-md-block">
                                                    <div class=" mb-2">

                                                        <div class="">
                                                            <iframe width="350" height="200"
                                                                src="https://www.youtube.com/embed/0EYT7k7u5jU"
                                                                title="Why Choose DigiWill?" frameborder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowfullscreen></iframe>

                                                            <p>Why Choose DigiWill? </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4 clearfix d-none d-md-block">
                                                    <div class=" mb-2">

                                                        <div class="">
                                                            <iframe width="350" height="200"
                                                                src="https://www.youtube.com/embed/G9kaso_ijmI"
                                                                title="Solution to keeps your information beyond safe - DigiWill App"
                                                                frameborder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowfullscreen></iframe>
                                                            <p>Solution to keeps your information beyond safe-DigiWill App. </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {/* <!--/.Second slide--> */}
                                    </div>
                                    {/* <!--/.Slides--> */}
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                                    data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                                    data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* youtube video section end */}
            {/* <!-- section 15 partner logo start --> */}
            <section id="partner-logo">
                <div class="container">
                    <div class="row">
                        <div class="col-12 mb-4">
                            <h1 class="text-center mb-2" >Recognized by</h1>
                        </div>
                        <div class="container ">
                            <div class="row align-items-center ">
                                <div class="container rounded">
                                    <div class="d-flex flex-row flex-wrap text-center partner-logo-images ">
                                        <div class="col-lg-3 col-md-3 col-12">
                                            <img src="./images/landing/logo/aws.png" alt="" />
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-12">
                                            <img src="./images/landing/logo/satyamev.png" alt="" />
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-12">
                                            <img src="./images/landing/logo/digilocker.png" alt="" />
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-12">
                                            <img src="./images/landing/logo/startup.png" alt="" />
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- section 15 partner logo end --> */}



        </div>);
}

export default Landing;
