import React, { useState, useEffect, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { AppContext } from '../user/AppContext';
import { PLAYSTORE_URL } from './pages/urls';
import Popper from './Popper';

function Navbar() {
  const width = window.innerWidth

  // const [show, setShow] = useState("flex")
  const { show, setShow } = useContext(AppContext)
  const [explore, setexplore] = useState(false)
  const [resource, setresource] = useState(false)
  const handletoggle = (e) => {
    if (show == "none") {
      setShow("flex")

    }
    else {
      setShow("none")
    }

  }
  useEffect(() => {

    if (width <= 972) {
      setShow('none')
    }
    else {
      setShow('flex')
    }

  }, [])

  return (
    <>
      {/* <Popper /> */}
      <header>
        <nav className="navbar navbar-expand-lg cx-navbar" >
          <div className="container">
            <Link className="navbar-brand" to="/"><img src="https://d2x7e1xt27q73s.cloudfront.net/whitee.png" alt='DigiWill - Aapki Virasat' /></Link>
            <button className="navbar-toggler  cx-toggler" onClick={(e) => handletoggle(e)} type="button" >
              <span><i className='bx bx-menu'></i></span>
            </button>
            <div className="mx-auto" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0" style={{ display: show }} id="cx-navbar-nav">
                <li className="nav-item cx-side-logo">
                  <a className="nav-link" aria-current="page" href="#"><img src="../images/home/DigiWill_logo.svg" /></a>
                </li>
                <li className='nav-item '>
                  <Link className="nav-link" to="/">Home</Link>
                </li>
                {/* <li className="nav-item" id="cx-edrop">
                        <a className="nav-link" onClick={()=>{setexplore(!explore)}}  id="navbarDropdown" role="button">
                       Home
                            <img src="images/navbar/dropdown.svg" className="cx-nav-droparrow" alt="dropdown" />
                        </a>
                        {/* <ul className={explore ? 'cx-dropdown show' : 'cx-dropdown'} id="cx-edropdown">
                        <li><Link className="dropdown-item" to="/">Home</Link></li>
                            <li><Link className="dropdown-item" to="/tracker">Family Communication</Link></li>
                            <li><Link className="dropdown-item" to="/ensurance">Ensurance Plan</Link></li>
                            <li><Link className="dropdown-item" to="/service_partner">Service Partners</Link></li>
                            <li><Link className="dropdown-item" to="/channel_partner">Channel Partners</Link></li>
                            <li><Link className="dropdown-item" to="/invest">Invest In</Link></li>
                           {/*  <li><a className="dropdown-item" href="#">Self Interest Will Draft</a></li> */}
                {/* <li><a className="dropdown-item" href="#">Digicoin</a></li> */}
                {/* <li><Link className="dropdown-item" to="/hot_deals">Hotdeals</Link></li> 
                        </ul> 
                      </li> */}
                <li className="nav-item" id="cx-rdrop">
                  <a className="nav-link" onClick={() => { setresource(!resource) }} id="navbarDropdown" role="button">
                    Partners
                    {/* <img src="/images/navbar/dropdown.svg" className="cx-nav-droparrow" alt="dropdown" /> */}
                  </a>
                  <ul className={resource ? 'cx-dropdown show' : 'cx-dropdown'} id="cx-rdropdown">
                    <li><Link className="dropdown-item" to="/service-partner">Service Partners</Link></li>
                    <li><Link className="dropdown-item" to="/channel-partner">Channel Partners</Link></li>
                    <li><Link className="dropdown-item" to="/business-page">Business to Business</Link></li>
                    {/* <li><Link className="dropdown-item" to="/blog">Blogs</Link></li>
                            <li><Link className="dropdown-item" to="/videos">Videos</Link></li>
                            <li><Link className="dropdown-item" to="/curosity_box">Curiosity Box</Link></li> */}
                    {/*        <li><a className="dropdown-item" href="#">Media</a></li> */}
                    {/* <li><Link className="dropdown-item" to="/about">About Us</Link></li> */}
                    {/* <li><a className="dropdown-item" href="#">Contact Us</a></li> */}
                  </ul>
                </li>

                <li className='nav-item'>
                  <Link className="nav-link" to="/blog">Blogs</Link>
                </li>
                <li className='nav-item'>
                  <Link className="nav-link" to="/pricing">Pricing</Link>
                </li>
                <li className='nav-item'>
                  <Link className="nav-link" to="/about">About Us</Link>
                </li>
                <li className='nav-item contact-get-align'>
                  <Link className="nav-link " to="/contact-get">Contact Us</Link>
                </li>
                {/* <li className="nav-item ">
                <button className="btn-claim btn btn-warning " aria-current="page" href="#"><Link to="/claim" >Claim It</Link></button>
              </li> */}

                {/* <li className="nav-item">
                        <a className="nav-link" aria-current="page" href="#">Signup</a>
                    </li> */}
                {/* <li className="nav-item">
                        <a className="nav-link  nav-btn-cx" aria-current="page" href="#"><Link to="/claim" >Claim Now</Link></a>
                    </li>  */}

                <li className="nav-item claim-it-align">
                  <button className="btn-claim btn btn-warning " aria-current="page" href="#"><Link to="/claim" >Claim It</Link></button>
                </li>
                {/* <li className="nav-item">
                        <a className="nav-link cx-store" aria-current="page"  href={PLAYSTORE_URL}><img src="images/home/google_play_circle.png"/></a>
                    </li> */}
                {/* <li className="nav-item">
                        <a className="nav-link cx-store" aria-current="page" href={PLAYSTORE_URL}><img src="images/home/app_store_circle.png"/></a>
                    </li> */}

              </ul>

              {/* <div>
          <button className="btn-claim btn btn-warning " aria-current="page" href="#"><Link to="/claim" className='claim-btn'>Claim It</Link></button>      
          </div>  */}
            </div>

          </div>

        </nav>

      </header>
    </>

  );
}

export default Navbar;
